import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BookingsListElement from './BookingsListElement';


class BookingsList extends Component {
	render() {
		const addNewBookingButton = 
		<input type='button' 
			name='newBooking' 
			onClick={
				!this.props.newBookingMode && !this.props.newBookingIsCorrect 
				? this.props.toggleNewBookingMode
				: this.props.newBookingMode && this.props.newBookingIsCorrect
					? this.props.addBookingToUnconfirmedCollection
					: undefined
			} 
			value={this.props.newBookingHeader}
			style={!this.props.newBookingMode ? {position: 'absolute', right: 0} : {position: 'absolute', left: 0}}
			className='btn btn-dark col-6'
		/>;
		const cancelAddNewBookingButton = <input type='button' 
		className='btn btn-danger col-6' 
		style={{position: 'absolute', right: 0}}
		name='cancel' 
		value={this.props.cancelBookingButtonText} 
		onClick={this.props.toggleNewBookingMode}/>
		//create listing from userSelect in showBookingDetails or all booking for current month in selected bookingType
		const bookingsForListing = this.props.showBookingDetails !== false && this.props.BookingList ?
			this.props.bookingList.filter((booking) =>{
				return booking.bookingId === this.props.showBookingDetails
			}) : this.props.bookingList;
		return (
			<div className='card'>
		  	<div className='card-header' id='Bookings'>
		  		<h3>{!this.props.newBookingMode && this.props.header}
		  			{addNewBookingButton}
		  			{this.props.newBookingMode && cancelAddNewBookingButton}
		  		</h3>
		  	</div>
		    <div className='card-body'>
		    	<div className='card-title'>	
		    	</div>
		    	<div className='card-text'>
		    		{Array.isArray(bookingsForListing) && bookingsForListing.map((booking, index)=>(
					    <BookingsListElement
						    key={index}
						    booking={booking}
						    bookingTypeColors={this.props.bookingTypeColors}
						    createCalendarView={this.props.createCalendarView}
						    monthHeaders={this.props.monthHeaders}
						   	confirmBookingButtonText={this.props.confirmBookingButtonText}
								cancelBookingButtonText={this.props.cancelBookingButtonText}
						    changeBookingCollection={this.props.changeBookingCollection}
					    />
				   ))}	
		    	</div>
		    </div>
	  </div>
	  )
	}
}

BookingsList.propTypes ={
	bookings: PropTypes.arrayOf(PropTypes.object),
	monthHeaders: PropTypes.arrayOf(PropTypes.string)

}

export default BookingsList;



