import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BookingsListElement extends Component {
	constructor(props){
		super(props);
		this.setCanceled = this.setCanceled.bind(this);
		this.setConfirmed = this.setConfirmed.bind(this);
	}
	setCanceled(){
		this.props.changeBookingCollection(this.props.booking.bookingId, this.props.booking.bookingType, 'canceled');
		this.props.createCalendarView();
	}
	setConfirmed(){
		this.props.changeBookingCollection(this.props.booking.bookingId, this.props.booking.bookingType, 'confirmed');
		this.props.createCalendarView();
	}
	render() {
	const checkIn = this.props.booking.checkin;
	const checkOut = this.props.booking.checkout;
	let checkInElement; 
	let checkOutElement;
	checkIn 
	? checkInElement = <div>Check-In: {checkIn.getDate()+ ' ' + this.props.monthHeaders[checkIn.getMonth()] + ' ' + checkIn.getFullYear()}</div>
	: checkInElement = <div>Check-In: ...</div>;
	checkOut 
	? checkOutElement = <div>Check-Out: {checkOut.getDate()+ ' ' + this.props.monthHeaders[checkOut.getMonth()] + ' ' + checkOut.getFullYear()}</div>
	: checkOutElement = <div>Check-Out: ...</div>;
	const cardClasses = {
		confirmed: "card-header bg-"+this.props.bookingTypeColors['confirmed'],
		unconfirmed: "card-header bg-"+this.props.bookingTypeColors['unconfirmed'],
		canceled: "card-header bg-"+this.props.bookingTypeColors['canceled']
	}
	const confirmButton = <input type="button" 
		className={'btn btn-'+this.props.bookingTypeColors['confirmed']+' col-6'} 
		onClick={this.setConfirmed} 
		value={this.props.confirmBookingButtonText}/>;
	const cancelButton = <input type="button" 
		className={'btn btn-'+this.props.bookingTypeColors['canceled']+' col-6'} 
		onClick={this.setCanceled} 
		value={this.props.cancelBookingButtonText}/>;
	const buttonGroup = <div className="row">{confirmButton} {cancelButton}</div>;
	const bookingControlButtonGroup = 
		this.props.booking.bookingType === 'confirmed' 
		? cancelButton 
		: this.props.booking.bookingType === 'unconfirmed' && this.props.booking.bookingId !== 'new'
			? buttonGroup
			: '';
		return (
			<div id={this.props.booking.bookingId} className="card">
	    	<div className={cardClasses[this.props.booking.bookingType]} id={this.props.booking.bookingId}>
						<h6>{"id: " + this.props.booking.bookingId}</h6>
		  	</div>
	    <div id={"booking"+this.props.booking.bookingId}>
		    <div className="card-body">
		    	<div className="card-text">
		    		{checkInElement}
		    		<br/>
						{checkOutElement}
						{bookingControlButtonGroup}
						
		    	</div>
		    </div>
	    </div>
	  </div>
		)
	}
}
BookingsListElement.propTypes ={
	booking: PropTypes.object,
	monthHeaders: PropTypes.arrayOf(PropTypes.string)

}
export default BookingsListElement;