const langObject = {
	monthHeaders: {
		gb: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		pl: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
		de: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
		no: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
	},
	weekDayHeaders: {
		gb: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
		pl: ['Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb', 'Nd'],
		de: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
		no: ['Ma', 'Ti', 'On', 'To', 'Fr', 'Lø', 'Sø'],
	},
	collectionHeader: {
		gb: 'Bookings',
		pl: 'Rezerwacje',
		de: 'Reservierungen',
		no: 'Reservasjoner',
	},
	newBookingHeader: {
		gb: 'New booking',
		pl: 'Nowa rezerwacja',
		de: 'Neue Reservierung',
		no: 'Ny reservasjon',
	},
	selectCheckInHeader: {
		gb: 'Select Check-In ...',
		pl: 'Wybierz początek ...',
		de: 'Select Check-In ...',
		no: 'Select Check-In ...',
	},
	selectCheckOutHeader: {
		gb: 'Select Check-Out ...',
		pl: 'Wybierz koniec ...',
		de: 'Select Check-Out ...',
		no: 'Select Check-Out ...',
	},
	addBookingHeader: {
		gb: 'Add booking ...',
		pl: 'Dodaj rezerwację ...',
		de: 'Add booking ...',
		no: 'Add booking ...',
	},
	roomHeader:
	{
		gb: 'Rooms',
		pl: 'Pokoje',
		de: 'Zimmer',
		no: 'Rom',
	},
	languageHeader:
	{
		gb: 'Language',
		pl: 'Język',
		de: 'Sprache',
		no: 'Språk',
	},
	nextHeader:
	{
		gb: 'Next',
		pl: 'Następny',
		de: 'Nächste',
		no: 'Neste',
	},
	previousHeader: {
		gb: 'Previous',
		pl: 'Poprzedni',
		de: 'Vorige',
		no: 'Forrige',
	},
	confirmed: {
			gb: 'Confirmed',
			pl: 'Potwierdzone',
			de: 'Bestätigte',
			no: 'Bekreftet',
	},
	unconfirmed: {
			gb: 'Unconfirmed',
			pl: 'Niepotwierdone',
			de: 'Unbestätigte',
			no: 'Ubekreftet',
	},
	canceled: {
			gb: 'Canceled',
			pl: 'Anulowane',
			de: 'Stornierte',
			no: 'Kansellert',
	},
	confirmBookingButton: {
			gb: 'Confirm',
			pl: 'Potwierdź',
			de: 'Bestätige',
			no: 'Bekreft',
	},
	cancelBookingButton: {
			gb: 'Cancel',
			pl: 'Anuluj',
			de: 'Stornieren',
			no: 'Avbryt',
	}
};

const chooseLanguage = (lang) =>
{
	let outputObject = {};
	for (let [collectionName, languagesCollections] of Object.entries(langObject)) {
		outputObject[collectionName] = languagesCollections[lang];
	}
	return outputObject;
}
export {chooseLanguage};