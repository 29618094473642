import React, { Component } from 'react';
import './popup.css'
import { getClientData } from './DataModelEngine';

class CallPopup extends Component {
	render() {
		return (
			<div className='call-popup'>
				<p className='call-popup-close-button' onClick={this.props.closeCallPopup}>X</p>
				<a href={'tel:'+getClientData('clientPhone')}>
                    <div className='ring' style={
                        {

                            backgroundRepeat: 'no-repeat',
                            width: "100%",
                            height: "100%",

                        }
                    }></div>
                </a>

			</div>
		) 
	}
}

export default CallPopup;