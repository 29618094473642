import React, { Component } from 'react';
import ElsaLoading from './ElsaLoading';
import ElsaTextDisplay from './ElsaTextDisplay';
import { getClientData } from './../DataModelEngine';

class ElsaScript extends Component {
	constructor (props) {
		super(props);
		this.state = {
		};
		this.chooseScene = this.chooseScene.bind(this);
	};

	chooseScene(event){
	const name = event.target.getAttribute('name');
	const id = +event.target.getAttribute('id');	
	this.props.chooseScene(name, id);
	}


	render() {
		let ElsaTextShown = this.props.elsaScript ? this.props.elsaScript : [''];
		const elsaButtons = this.props.elsaButtons ? this.props.elsaButtons : [];
		const done = this.props.scriptShown;
		return (
		<>
            <ElsaTextDisplay scriptShown={done} elsaScript={ElsaTextShown}/>
			{elsaButtons && elsaButtons.map((button, index)=>
				<>
					{done && Array.isArray(button.link) && <div id='elsa-continue'>
						<p 
						id={button.link[1]} 
						name={button.link[0]}  
						onClick={this.chooseScene}>{button.text}<ElsaLoading/></p></div>}
					{done && typeof(button.link) === 'string' && button.link === 'continue' && <div id='elsa-continue'>
						<p 
						id={button.link} 
						name={button.link}  
						onClick={this.props.changeScene}>{button.text}<ElsaLoading/></p></div>}
					{done && typeof(button.link) === 'string' && button.link === 'call' && <div id='elsa-continue'>
					<a href={'tel:'+getClientData('clientPhone')}><p 
						id={button.link} 
						name={button.link}  
						onClick={this.props.changeScene}>{button.text}<ElsaLoading/></p></a></div>}
					{done && typeof(button.link) === 'string' && button.link === 'epost' && <div id='elsa-continue'>
					<a href={'mailto:'+getClientData('clientEmail')}><p 
						id={button.link} 
						name={button.link}  
						onClick={this.props.changeScene}>{button.text}<ElsaLoading/></p></a></div>}
					{done && typeof(button.link) === 'string' && button.link === 'kontaktskjema' && <div id='elsa-continue'>
					<a href={'#kontakt'}><p 
						id={button.link} 
						name={button.link}  
						onClick={this.props.changeScene}>{button.text}<ElsaLoading/></p></a></div>}
					{done && typeof(button.link) === 'string' && button.link === 'portfolio' && <div id='elsa-continue'>
					<a href={'#about'}><p 
						id={button.link} 
						name={button.link}  
						onClick={this.props.changeScene}>{button.text}<ElsaLoading/></p></a></div>}
				</>
			)}
			</>
		)
	}
}
 //
export default ElsaScript;