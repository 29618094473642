
function getButton(buttonType, lang) {
    const buttons = {
        continue: {
            text: {
                no: 'Fortsett',
                pl: 'Kontynuuj',
                en: 'Continue',
            },
            link: 'continue'
        },
        // ------- HELLO -----
        askforprice: {
            text: {
                no: 'Be om et individuelt tilbud',
                pl: 'Zapytaj o indywidualną ofertę',
                en: 'Ask for an individual offer',
            },
            link: ['tilbud', 0]
        },
        kontakt: {
            text: {
                no: 'Kontakt oss',
                pl: 'Kontakt',
                en: 'Contact us',
            },
            link: ['kontakt', 0]
        },
        epost: {
            text: {
                no: 'Skriv en e-post',
                pl: 'Napisz e-mail',
                en: 'Write an e-mail',
            },
            link: 'epost'
        },
        kontaktskjema: {
            text: {
                no: 'Kontaktskjema',
                pl: 'Formularz kontakt',
                en: 'Contact form',
            },
            link: 'kontaktskjema'
        },
        tlf: {
            text: {
                no: 'Ring 48 65 16 66',
                pl: 'Zadzwoń 48 65 16 66',
                en: 'Call 48 65 16 66',
            },
            link: 'call'
        },
        newsletter: {
            text: {
                no: 'Newsletter',
                pl: 'Newsletter',
                en: 'Newsletter',
            },
            link: 'newsletter'
        },  
        facebook: {
            text: {
                no: 'Følg oss på Facebook',
                pl: 'Odwiedź nas na Facebook',
                en: 'Follow us on Facebook',
            },
            link: 'facebook'
        },
        instagram: {
            text: {
                no: 'Se på Instagram',
                pl: 'Zobacz Instagram',
                en: 'Check out Instagram',
            },
            link: 'instagram'
        },        
        linkedin: {
            text: {
                no: 'LinkedIn',
                pl: 'LinkedIn',
                en: 'LinkedIn',
            },
            link: 'linkedin'
        },  

        offer: {
            text: {
                no: 'Om tilbudet',
                pl: 'Oferta',
                en: 'About our offer',
            },
            link: ['offer', 0]
        },

        mainmenu: {
            text: {
                no: 'Hovedmeny',
                pl: 'Menu główne',
                en: 'Main menu',
            },
            link: ['offer', 1]
        },

        aboutus: {
            text: {
                no: 'Om Laskowski Creative',
                pl: 'O Laskowski Creative',
                en: 'About Laskowski Creative',
            },
            link: ['aboutus', 0]
        },


        // --------- GRAPHIC DESIGN ---------
        graphicdesign: {
            text: {
                no: 'Grafisk design',
                pl: 'Projektowanie graficzne',
                en: 'Graphic design',
            },
            link: ['graphicdesign', 0]
        },
        logo: {
            text: {
                no: 'Logo design',
                pl: 'Projektowanie logo',
                en: 'Logo design',
            },
            link: ['graphicdesign', 1]
        },   
        cards: {
            text: {
                no: 'Visitkort',
                pl: 'Wizytówki',
                en: 'Cards',
            },
            link: ['graphicdesign', 2]
        }, 
        carads: {
            text: {
                no: 'Reklame på biler',
                pl: 'Oklejanie aut',
                en: 'Car ads',
            },
            link: ['graphicdesign', 3]
        },   
        layouts: {
            text: {
                no: 'Nettsideoppsett',
                pl: 'Layout strony',
                en: 'Website Layouts',
            },
            link: ['graphicdesign', 4]
        }, 
        graphicservices: {
            text: {
                no: 'Grafiske Tjenester',
                pl: 'Usługi graficzne',
                en: 'Graphic Services',
            },
            link: ['graphicdesign', 5]
        }, 
        graphicserviceedit: {
            text: {
                no: 'Bilderedigering',
                pl: 'Retusz zdjęć',
                en: 'Editing photos',
            },
            link: ['graphicdesign', 6]
        },   
        graphicservicelabels: {
            text: {
                no: 'Emballasje og etiketter',
                pl: 'Opakowania i etykiety',
                en: 'Packaging and labels',
            },
            link: ['graphicdesign', 7]
        },    
        graphicserviceadsproducts: {
            text: {
                no: 'Reklameprodukter',
                pl: 'Produkty reklamowe',
                en: 'Advertising products',
            },
            link: ['graphicdesign', 8]
        },      
        logoexample: {
            text: {
                no: 'Eksempler på logo',
                pl: 'Przykłady logo',
                en: 'Logo examples',
            },
            link: 'portfolio'
        },       
        // --------- CODE ---------
        code: {
            text: {
                no: 'Webdesign',
                pl: 'Projektowanie stron internetowych',
                en: 'Webdesign',
            },
            link: ['website', 0]
        },
        codeintegration: {
            text: {
                no: 'Applikasjonsintegrasjon',
                pl: 'Integracja aplikacji',
                en: 'Application integration',
            },
            link: ['code', 1]
        },
        website: {
            text: {
                no: 'Webdesign',
                pl: 'Projektowanie stron internetowych',
                en: 'Webdesign',
            },
            link: ['website', 0]
        },    
        websitequick: {
            text: {
                no: 'Side om en uke',
                pl: 'Strona w tydzień',
                en: 'Page in a week',
            },
            link: ['website', 1]
        },     
        websitequality: {
            text: {
                no: 'Kvalitetsnettsted',
                pl: 'Teksty na stronę',
                en: 'Quality website',
            },
            link: ['website', 2]
        },                        
        websitecopywright: {
            text: {
                no: 'Copywriting',
                pl: 'Teksty na stronę',
                en: 'Copywriting',
            },
            link: ['website', 3]
        },  
        modules: {
            text: {
                no: 'Utvidelser',
                pl: 'Rozszerzenia',
                en: 'Extensions',
            },
            link: ['website', 4]
        }, 
        modulebookingcalendar: {
            text: {
                no: 'Reservasjonssystem',
                pl: 'System rezerwacji',
                en: 'Reservation system',
            },
            link: ['website', 5]
        },  
        modulevirtualassistant: {
            text: {
                no: 'Virtuell assistent',
                pl: 'Wirtualny asystent',
                en: 'Virtual assistant',
            },
            link: ['website', 6]
        },           
        modulecontactform: {
            text: {
                no: 'Kontakt skjema',
                pl: 'Formularz kontaktowy',
                en: 'Contact form',
            },
            link: ['website', 7]
        },
        websiteprice: {
            text: {
                no: 'Nettside pris',
                pl: 'Cena strony internetowej',
                en: 'Website price',
            },
            link: ['website', 8]
        },
        websiteannualfee: {
            text: {
                no: 'Årsavgift',
                pl: 'Opłata roczna',
                en: 'Annual fee',
            },
            link: ['website', 9]
        },  
        websiteadminpanel: {
            text: {
                no: 'Administrasjonspanel',
                pl: 'Panel administracyjny',
                en: 'Admin panel',
            },
            link: ['website', 10]
        },        
        websiteexample: {
            text: {
                no: 'Se eksempel',
                pl: 'Zobacz przykład',
                en: 'See example',
            },
            link: 'portfolio'
        },
        websiteemail: {
            text: {
                no: 'Bedriftens e-postadresse',
                pl: 'Profesjonalny adres mailowy',
                en: 'Business email address',
            },
            link: ['website', 12]
        },        
        //---------- ESTORE ---------
        estore: {
            text: {
                no: 'Nettbutikk',
                pl: 'Sklep online',
                en: 'E-store',
            },
            link: ['estore', 0]
        },
        estoreinput: {
            text: {
                no: 'Inndata av forsyninger i databasen',
                pl: 'Zatowarowanie sklepu internetowego',
                en: 'Input of supplies into the database',
            },
            link: ['estore', 1]
        },    
        estoreadminpanel: {
            text: {
                no: 'Administrasjonspanel',
                pl: 'Panel administracyjny',
                en: 'Admin panel',
            },
            link: ['estore', 2]
        },  
        estoreprice: {
            text: {
                no: 'Nettside pris',
                pl: 'Cena strony internetowej',
                en: 'Website price',
            },
            link: ['estore', 3]
        },
        estoreannualfee: {
            text: {
                no: 'Årsavgift',
                pl: 'Opłata roczna',
                en: 'Annual fee',
            },
            link: ['estore', 4]
        },  
  //-------------- APPS -------------
        app: {
            text: {
                no: 'Applikasjon',
                pl: 'Programy',
                en: 'App',
            },
            link: ['app', 0]
        },  
        appprice: {
            text: {
                no: 'Applikasjon pris',
                pl: 'Cena napisania programu',
                en: 'App price',
            },
            link: ['app', 3]
        },
        appannualfee: {
            text: {
                no: 'Årsavgift',
                pl: 'Opłata roczna',
                en: 'Annual fee',
            },
            link: ['app', 4]
        },  

        
        // --------- MARKETING ---------
        marketing: {
            text: {
                no: 'Online Marketing',
                pl: 'Marketing Online',
                en: 'Online Marketing',
            },
            link: ['marketing', 0]
        },    
        googleads: {
            text: {
                no: 'Google Ads',
                pl: 'Google Ads',
                en: 'Google Ads',
            },
            link: ['marketing', 1]
        }, 
        facebookads: {
            text: {
                no: 'Facebook Ads',
                pl: 'Facebook Ads',
                en: 'Facebook Ads',
            },
            link: ['marketing', 2]
        },  
        socialmedia: {
            text: {
                no: 'Sosiale Medier',
                pl: 'Social Media',
                en: 'Social Media',
            },
            link: ['marketing', 3]
        },        
        googlemybusiness: {
            text: {
                no: 'Google Min Bedrift',
                pl: 'Google Moja Firma',
                en: 'Google My Business',
            },
            link: ['marketing', 4]
        }, 
        finn: {
            text: {
                no: 'Finn.no',
                pl: 'Finn.no',
                en: 'Finn.no',
            },
            link: ['marketing', 5]
        },
        mittanbud: {
            text: {
                no: 'Mittanbud.no',
                pl: 'Mittanbud.no',
                en: 'Mittanbud.no',
            },
            link: ['marketing', 6]
        }, 
        marketingbranding: {
            text: {
                no: 'Merkevarebygging',
                pl: 'Tworzenie marki',
                en: 'Branding',
            },
            link: ['marketing', 7]
        },         
        marketingseo: {
            text: {
                no: 'SEO plassering',
                pl: 'pozycjonowanie SEO',
                en: 'SEO positioning',
            },
            link: ['marketing', 8]
        },  
        // --------- FOTO ---------
        fotovideo: {
            text: {
                no: 'Foto & Video',
                pl: 'Usługi foto',
                en: 'Photo & Video',
            },
            link: ['foto', 0]
        },     
        foto: {
            text: {
                no: 'Foto',
                pl: 'Foto',
                en: 'Photo',
            },
            link: ['foto', 1]
        },  
        fotosession: {
            text: {
                no: 'Fotoøkt',
                pl: 'Sesja fotograficzna',
                en: 'Photo shoot',
            },
            link: ['foto', 2]
        }, 
        fotosessionproduct: {
            text: {
                no: 'Produktøkt',
                pl: 'Sesja produktowa',
                en: 'Photo shoot',
            },
            link: ['foto', 3]
        },          
        fotosessionportrait: {
            text: {
                no: 'Portretter',
                pl: 'Sesja portretowa',
                en: 'Portraits',
            },
            link: ['foto', 4]
        },          
        fotosessionreports: {
            text: {
                no: 'Fotorapporter',
                pl: 'Fotorelacja',
                en: 'Photo reports',
            },
            link: ['foto', 5]
        },  
        fotosessionarchitecture: {
            text: {
                no: 'Arkitekturfotografering',
                pl: 'Fotografia architektoniczna',
                en: 'Architecture photography',
            },
            link: ['foto', 6]
        },  
        fotosessioninterior: {
            text: {
                no: 'Interiørfotografering',
                pl: 'Fotografia wnętrz',
                en: 'Interior photography',
            },
            link: ['foto', 7]
        },
        fotosessionfamily: {
            text: {
                no: 'Familieøkter',
                pl: 'Sesja rodzinne',
                en: 'Family session',
            },
            link: ['foto', 8]
        },        
        fotosessionevent: {
            text: {
                no: 'Eventfotografering',
                pl: 'Fotografia eventowa',
                en: 'Event photography',
            },
            link: ['foto', 9]
        }, 
        fotosessiondrone: {
            text: {
                no: 'Droneøkter',
                pl: 'Sesje dronem',
                en: 'Drone sessions',
            },
            link: ['foto', 9]
        },
        fotosessionquick: {
            text: {
                no: 'Hurtig økt',
                pl: 'Szybka sesja',
                en: 'Quick session',
            },
            link: ['foto', 10]
        },         
        videosession: {
            text: {
                no: 'Videoøkt',
                pl: 'Sesja wideo',
                en: 'Video session',
            },
            link: ['foto', 12]
        },  
        videoads: {
            text: {
                no: 'Videoannonser',
                pl: 'Reklamy wideo',
                en: 'Video ads',
            },
            link: ['foto', 13]
        },  
        // --------- SUPPORT IT ---------         
        supportit: {
            text: {
                no: 'IT støtte',
                pl: 'Wsparcie IT',
                en: 'support IT',
            },
            link: ['supportit', 0]
        },    
        supportitbusiness: {
            text: {
                no: 'IT-støtte for bedrifter',
                pl: 'Wsparcie IT dla firm',
                en: 'Business IT support',
            },
            link: ['supportit', 1]
        },   
        supportitremote: {
            text: {
                no: 'Fjernassistanse',
                pl: 'Pomoc zdalna',
                en: 'Remote IT Assistance',
            },
            link: ['supportit', 2]
        },            
        supportittraining: {
            text: {
                no: 'IT-kurs',
                pl: 'Szkolenie IT',
                en: 'IT course',
            },
            link: ['supportit', 3]
        },
        supportitfix: {
            text: {
                no: 'Datamaskinreparasjon',
                pl: 'Naprawa komputerów',
                en: 'Computer repair',
            },
            link: ['supportit', 4]
        },
        supportitprice: {
            text: {
                no: 'Abonnementstjeneste',
                pl: 'Usługa abonamentowa',
                en: 'Subscription service',
            },
            link: ['supportit', 5]
        },       
        
        packagelogocard: {
            text: {
                no: 'Mest populære tilbud',
                pl: 'Najczęściej wybierana oferta',
                en: 'Best offer',
            },
            link: ['packageprice', 0]
        },           
        packagelogocardcar: {
            text: {
                no: 'Mest populære tilbud',
                pl: 'Najczęściej wybierana oferta',
                en: 'Best offer',
            },
            link: ['packageprice', 1]
        },
        packagewebsiteads: {
            text: {
                no: 'Mest populære tilbud',
                pl: 'Najczęściej wybierana oferta',
                en: 'Best offer',
            },
            link: ['packageprice', 2]
        }, 
        packagesocialads: {
            text: {
                no: 'Mest populære tilbud',
                pl: 'Najczęściej wybierana oferta',
                en: 'Best offer',
            },
            link: ['packageprice', 3]
        },
        packagesocialadsfoto: {
            text: {
                no: 'Mest populære tilbud',
                pl: 'Najczęściej wybierana oferta',
                en: 'Best offer',
            },
            link: ['packageprice', 3]
        },

        //ABOUT US 


    
    };
    const requestedButton = {
        text: buttons[buttonType]['text'][lang],
        link: buttons[buttonType]['link']
    };
    return requestedButton;
}


export {getButton};