
import React, { Component } from 'react';
import LogoGallery from './LogoGallery';
import PicturesGallery from './PicturesGallery';

class AboutUs extends Component {
	render() {
		const folderName = ['lc_projects'];
		const title = ['']
		const photos = 
		[
			{file: 'lc_project_02', fill: 110, x: 50, y: 50, class: 'lb', link: 'laskowskibygg.no'},
			{file: 'lc_project_03', fill: 110, x: 50, y: 70, class: 'vb', link: 'vyvobygg.no'},
			{file: 'lc_project_01', fill: 110, x: 50, y: 70, class: 'jp', link: 'jordansparkett.no'},
			{file: 'lc_project_05', fill: 110, x: 50, y: 50, class: 'sp', link: 'solcellepanelsett.no'},
			{file: 'lc_project_06', fill: 110, x: 50, y: 50, class: 'kl', link: 'kwatery-laskowscy.pl'},
			{file: 'lc_project_07', fill: 110, x: 60, y: 50, class: 'jl', link: 'jobbleder.no'},

		];
		const description = [
			''
		]
		
		return (
		<div id="about" className='gallery'>
			<div>
				{photos.map((logo, index)=>(
					<a href={'https://'+logo.link} target='_blank' rel="noopener noreferrer">
						<img className='logo-project' src={'./lc_projects/'+logo.file+'_logo.svg'}/>
					</a>
					//<PicturesGallery description={description} folderName={gallery} title={title[index]} photos={photos[index]}/>
				))}
			</div>
			
		</div>
		) 
	}
}

export default AboutUs;