import React, { Component } from "react";
import './article.css';
import AboutUs from './AboutUs';
import ContactPanel from './ContactPanel';
import Gallery from './Gallery';
import Preface from './Preface';
import ArticleIco from "./ArticleIco";

class SideMenuArticlePage extends Component {
    constructor () {
        super();
          this.state = {
            hoverOn: false
          }
        }
    onClick = (index) => {
        let selectedValue = +index;
        this.props.onSelect(selectedValue);
    }
  render (){ 
    let hoverOn = this.state.hoverOn;
    const siteContent = this.props.siteContent ? this.props.siteContent : [];
    const {selected} = this.props;
    return (
    <div className='side-menu'>      
        <ArticleIco siteContent={siteContent}/>  
        {window.innerWidth > window.innerHeight && siteContent && siteContent['page'].map((item, index)=>(    
            <div key={index} onClick={()=>this.onClick(index)} className='side-menu-item' style={{background: 'rgba(0,0,0,0.4)'}}>
            {/*<div className='picture'>  
                <img src={'./'+item.miniature.file} alt={item.title}/> 
            </div>*/}
                <h1 style={{display: window.innerWidth > 600 && hoverOn !== false && hoverOn === index ?  'none' : 'block'}}>{item.title}</h1>
             </div>
        ))}
        {window.innerWidth < window.innerHeight && siteContent && siteContent['page'].map((item, index)=>(    
            (index === selected+1 || index === selected+2 || index === selected-1) && <div key={index} onClick={()=>this.onClick(index)} className='side-menu-item' style={{background: 'rgba(0,0,0,0.4)'}}>
            {/*<div className='picture'>  
                <img src={'./'+item.miniature.file} alt={item.title}/> 
            </div>*/}
                <h1 style={{display: window.innerWidth > 600 && hoverOn !== false && hoverOn === index ?  'none' : 'block'}}>{item.title}</h1>
             </div>
        ))}
    </div>
    )}
}

export default SideMenuArticlePage;