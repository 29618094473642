import React, { Component } from "react";
import './elsa.css'
import { sceneplay } from "./ElsaParticlesScenes";

import ElsaPopup from "./ElsaPopup";
import ElsaScript from "./ElsaScript";

import boring from './boring.mp4';
import muteIco from './mute.png';
import unmuteIco from './unmute.png';

class Elsa extends Component {
  constructor () {
    super();
	  this.state = {
      sceneplay: 'hello',
      scene: -1,
      text: [],
      done: false,
      mute: true,
     }
     this.changeScene = this.changeScene.bind(this);
     this.openElsa = this.openElsa.bind(this);
     this.closeElsa = this.closeElsa.bind(this);
     this.chooseScene = this.chooseScene.bind(this);
  }
  chooseScene(sceneplayName, scene){
    this.setState({
      sceneplay: sceneplayName, scene: scene-1,
    });  
    //console.log(sceneplayName);
    //console.log(scene);
    //console.log(this.state.sceneplay);
    //console.log(this.state.scene);
    const lang = this.props.lang;
    const sceneArray = sceneplay(sceneplayName, lang);
    //console.log(sceneArray);
      this.setState({
        scene,
        done: false,
      });
      let chosenScene = sceneArray[scene]['text'];
      chosenScene = chosenScene.split("");
      //console.log(chosenScene);
      const sceneLength = chosenScene.length;
      let step = 0;
      let ElsaTextShown = [];
      this.interval = setInterval(() => {
        ElsaTextShown = ElsaTextShown.concat(chosenScene.shift())
        step++;
        if (step >= sceneLength)
        {
          this.setState({
            elsaButtons: sceneArray[scene]['buttons'],
            done: true
          })
          clearInterval(this.interval);
        }
          //console.log(ElsaTextShown);
          //console.log(step);
        this.setState({
          text: ElsaTextShown
        })
      }, 70)

    }
  
  muteToggle = ()=>{
    let mute = this.state.mute;
    mute = !mute;
    this.setState({
      mute
    })
  }
  
  changeScene(){
    let scene = this.state.scene;
    const lang = this.props.lang;
    const sceneplayName = this.state.sceneplay;
    const sceneArray = sceneplay(sceneplayName, lang);
    if (scene < sceneArray.length-1)
    {
      scene++;
      this.setState({
        scene,
        done: false,
      })
      let chosenScene = sceneArray[scene]['text'];
      chosenScene = chosenScene.split("");
      const sceneLength = chosenScene.length;
      let step = 0;
      let ElsaTextShown = [];
      this.interval = setInterval(() => {
        ElsaTextShown = ElsaTextShown.concat(chosenScene.shift())
        step++;
        if (step >= sceneLength)
        {
          this.setState({
            elsaButtons: sceneArray[scene]['buttons'],
            done: true
          })
          clearInterval(this.interval);
        }
        ////console.log(ElsaTextShown);
        ////console.log(step);
        this.setState({
          text: ElsaTextShown
        })
      }, 100)
      
    }
    if (scene === sceneArray.length)
    {
      this.setState({
        scene: 0,
      })
    }
  }
  openElsa(){
    const scene = this.state.scene;
    const lang = this.props.lang;
    const sceneplayName = this.state.sceneplay;
    const sceneArray = sceneplay(sceneplayName, lang);
    if (scene === sceneArray.length-2)
    {
      this.setState({
        scene: -1,
      })
    }
    this.changeScene();
    this.props.openElsa();
  }
  closeElsa(){
    clearInterval(this.interval);
    const scene = this.state.scene;
    const lang = this.props.lang;
    //const sceneplayName = this.state.sceneplay;
    const sceneplayName = 'offer';
    const sceneArray = sceneplay(sceneplayName, lang);
    this.setState({
      scene: -1,
      sceneplay: 'offer'
    })
    /*if (scene === sceneArray.length-1)
    {
      this.setState({
        scene: scene-1,
      })
    }*/
    this.props.closeElsa();
  }

  componentDidMount() {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  };

  render (){
    const elsaToggle = this.props.elsa;
    const lang = this.props.lang;
    const sceneplayName = this.state.sceneplay;
    const scene = sceneplay(sceneplayName, lang);
    const {mute} = this.state;
    const ico = mute ? muteIco : unmuteIco;
    const done = this.state.done ? this.state.done : false;
    const elsaVideoClass = !done ? 'show' : 'hide';
    const elsaBoringClass = (done && !mute) || mute ? 'show' : 'hide';    
    
    return (
      <>
      {elsaToggle && <div id='lc-header'>
        <div id='elsa-close-button' onClick={this.closeElsa}>
          X
        </div>
        <div id='elsa'>
          <div id='elsa-glitch'></div>
          {!mute && <video id="elsa-video" className={elsaVideoClass} autostart='true' autoPlay='true' src={this.state.scene === -1 ? scene[0].file : scene[this.state.scene].file} type="video/mp4" />}
          {<video className={elsaBoringClass} autostart='true' autoPlay='true' loop='true' src={boring} type="video/mp4" />}
          <div id='elsa-mute-button' onClick={this.muteToggle}>
          {<div id='mute-ico' style={{backgroundImage: 'url('+ico+')'}}></div>}
        </div>
        </div>
        <div id='elsa-scripted'>
          {this.state.text.length && <ElsaScript elsaScript={this.state.text} elsaButtons={this.state.elsaButtons} scriptShown={this.state.done} changeScene={this.changeScene} chooseScene={this.chooseScene}/>}
        </div>
      </div>}
      {!elsaToggle && <ElsaPopup elsa={elsaToggle} openElsa={this.openElsa}/>} 
      </>

    )
  }
}

export default Elsa;
