import React, { Component } from "react";
import './App.css';
import AboutUs from './AboutUs';
import ContactPanel from './ContactPanel';
import SideMenuArticlePage from './SideMenuArticlePage';
import MainArticlePage from "./MainArticlePage";

class FullArticlePage extends Component {
    constructor () {
        super();
          this.state = {
            selected: 0
          }
        }
    onSelect = (index) => {
        this.setState({
            selected: index
        })
    }

  render (){  
    const {selected} = this.state;    
     return (
    <div id='article-page'>        
        {
          window.innerHeight < window.innerWidth && <SideMenuArticlePage selected={this.state.selected} onSelect={this.onSelect} siteContent={this.props.siteContent}/>
        }
        <MainArticlePage page={this.props.siteContent.page[selected]}/>
        {
          window.innerHeight > window.innerWidth && <SideMenuArticlePage selected={this.state.selected} onSelect={this.onSelect} siteContent={this.props.siteContent}/>
        }
    </div>
    )}
}

export default FullArticlePage;