const getClientData = function (type) {
    const clientData = {
        clientName: "Laskowski Creative",
        clientKey: "---",
        uid: "LC333",
        clientCompanyTaxNumber: "998 687 950 MVA",
        clientCompanyName: "Laskowski Creative",
        clientAddress: "Gamle Drammensvei 12B, 1369 Stabekk",
        clientDomain: "laskowski.no",
        clientEmail: "post@laskowski.no",
        clientPhone: "+4748651666",
        clientFancyPhone: '48 65 16 66',
        instagram: "laskowskicreative",
        facebook: "---",
    }
    return clientData[type];
}

export {getClientData};
