import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BookingRoomSelect extends Component {
	constructor(props){
		super(props);
		this.changeRoom = this.changeRoom.bind(this);
	}
	changeRoom(event){
		const value = parseInt(event.target.value);
		this.props.changeCalendarRoom(value);
	}
	render() {
		return (
			<div id="buttonsGroup">
				<h3>{this.props.header}</h3>
				{this.props.rooms && this.props.rooms.map((room, index)=>(
					<input type="button" key={index} name="roomId" id={room} value={room} style={{marginTop: "25px", left: "25px", width: "60px", height: "60px"}} className={room !== this.props.roomId ? "btn btn-outline-secondary" : "btn btn-secondary"} onClick={this.changeRoom}/>
				))}
			</div>
		);
	}
}

BookingRoomSelect.propTypes ={
	header: PropTypes.string.isRequired,
	rooms: PropTypes.arrayOf(PropTypes.number)
}

export default BookingRoomSelect;

