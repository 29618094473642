import React, { Component } from "react";
import './App.css';
import AboutUs from './AboutUs';
import ContactPanel from './ContactPanel';
import Gallery from './Gallery';
import Preface from './Preface';
import BookingCalendarLanguageSettings from "./Calendar/booking-calendar-utility/BookingCalendarLanguageSettings";
import DemoBookingsCalendarContainer from "./Calendar/DemoBookingsCalendarContainer";
import GalleryContainer from "./gallery/GalleryContainer";
import ArticleVideo from "./ArticleVideo";

class  MainArticlePage extends Component {
    constructor () {
        super();
          this.state = {
            hoverOn: false,
            nextPage: false,
          }
        }
    onHover = (index) => {
        this.setState({
            hoverOn: false
        })
    }
    onClick = () => {
        let {nextPage} = this.state;
        nextPage = !nextPage;
        this.setState({nextPage})
    }
  render (){ 
    let hoverOn = this.state.hoverOn;
    let headerColor = [
        'hsla(0, 0%, 100%, .6)',
        'hsla(0, 0%, 100%, .7)',
        'hsla(166, 25%, 85%, 1)',
        'radial-gradient(circle, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
        'radial-gradient(circle, rgba(141,159,171,1) 0%, rgba(9,87,121,1) 35%, rgba(3,25,39,1) 100%)',
        'linear-gradient(180deg, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
        'linear-gradient(360deg, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
        'linear-gradient(60deg,  rgba(141,159,171,1) 0%, rgba(9,87,121,1) 35%, rgba(3,25,39,1) 100%)',
        'linear-gradient(60deg, rgba(3,25,39,1) 0%,rgba(9,87,121,1) 35%, rgba(141,159,171,1) 100%)'];     
    const content = this.props.page.content ? this.props.page.content : [];
    const {title} = this.props.page;
    const gridTemplate = [
        '',
        '90%',
        '49.5% 49.5%',
        '32.7% 32.7% 32.7%',
        '24.3% 24.3% 24.3% 24.3%',
        '19.2% 19.2% 19.2% 19.2% 19.2%',
    ]
    const pictureLink = function (filename) {
        return '/galeria/before_after/'+filename;
      }
    const {nextPage} = this.state;
    return (
        
        <div id="article-content">
            <div>
                <h1>{title}</h1>
                {
                    content.map((line,index)=>(
                    <>
                        
                        {!nextPage  && typeof(line) === 'string' &&
                        <p>{line}</p>}
                        {!nextPage && typeof(line) === 'object' && !Array.isArray(line) &&
                        line.module === 'booking' && <DemoBookingsCalendarContainer/>}
                        {!nextPage && typeof(line) === 'object' && !Array.isArray(line) &&
                        line.module === 'gallery' &&<GalleryContainer galleryType='mini' gallery={window.innerWidth < 600 ? [0,15,0,0,0,0] : [0,21,0,0,0,0]}/>
                        }
                        {!nextPage && typeof(line) === 'object' && !Array.isArray(line) &&
                        line.module === 'portfolio' && 
                            <div id='logo-box'>
                                <GalleryContainer galleryType='products' menuNavigation={this.menuNavigation} gallery={[0,0,3,0,0,0]}/>
                            </div>
                        }
                        {!nextPage && typeof(line) === 'object' && !Array.isArray(line) &&
                        line.video &&<ArticleVideo videofile={line.video}/>
                        }    
                        {nextPage && typeof(line) === 'object' && !Array.isArray(line) &&
                        line.module === 'pagebreak' && <>
                            <MainArticlePage page={{content: line.content}}/>
                            <div id='page-break' onClick={this.onClick}>{line.button[1]}</div>
                        </>
                        }
                        {!nextPage && typeof(line) === 'object' && !Array.isArray(line) &&
                        line.module === 'pagebreak' && <>
                        <div id='page-break' onClick={this.onClick}>{line.button[0]}</div>
                        </>
                        }
                        {!nextPage && typeof(line) === 'object' && !Array.isArray(line) &&line.module === 'list' && 
                        <ul>
                            {line.list.map((item, idx)=>(<li>{item}</li>))}
                        </ul>
                        }               
                        {Array.isArray(line) && line.length === 2 &&
                            <div id="picture-before-after-frame">            
                                <div id="picture" style={{backgroundImage: 'url('+pictureLink(line[0])+')'}}>        
                                </div>
                                    <div id="picture" style={{backgroundImage: 'url('+pictureLink(line[1])+')'}}>        
                                </div>
                            </div>
                        }
                    </>                   
                    ))
                }
                
            </div>
        </div>
    )}
}

export default MainArticlePage;