import React, { Component } from "react";

import ad_1 from './ads/ad1_sd.mp4';


class VideoControls extends Component {
  constructor () {
    super();
	  this.state = {
      sceneplay: 'hello',
      scene: -1,
      text: [],
      done: false,
      mute: true,
     }

  }

  
  muteToggle = ()=>{
    let mute = this.state.mute;
    mute = !mute;
    this.setState({
      mute
    })
  }
  


  render (){
    return (
        <div id="video-controls" class="controls" data-state="hidden">
          <button id="playpause" type="button" data-state="play">Play/Pause</button>
          <button id="stop" type="button" data-state="stop">Stop</button>
          <div class="progress">
            <progress id="progress" value="0" min="0">
              <span id="progress-bar"></span>
            </progress>
          </div>
          <button id="mute" type="button" data-state="mute">Mute/Unmute</button>
          <button id="volinc" type="button" data-state="volup">Vol+</button>
          <button id="voldec" type="button" data-state="voldown">Vol-</button>
          <button id="fs" type="button" data-state="go-fullscreen">Fullscreen</button>
        </div>
    )
  }
}

export default VideoControls;
