const imgsArray = [
    {
        name: 'god_jul.png',
        minifile: 'god_jul.png',
        filename: 'god_jul.png',
        x: 50,
        y: 50,
        scale: 100,
        description: ['','',''],
        tags: ['','','',''],
        category: [1,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_1.jpg',
        minifile: 'mini_ostgulvsliping_1.jpg',
        filename: 'ostgulvsliping_1.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_2.jpg',
        minifile: 'mini_ostgulvsliping_2.jpg',
        filename: 'ostgulvsliping_2.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_1.jpg'],
    },
    {
        name: 'ostgulvsliping_3.jpg',
        minifile: 'mini_ostgulvsliping_3.jpg',
        filename: 'ostgulvsliping_3.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_4.jpg',
        minifile: 'mini_ostgulvsliping_4.jpg',
        filename: 'ostgulvsliping_4.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_5.jpg',
        minifile: 'mini_ostgulvsliping_5.jpg',
        filename: 'ostgulvsliping_5.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_6.jpg',
        minifile: 'mini_ostgulvsliping_6.jpg',
        filename: 'ostgulvsliping_6.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_7.jpg',
        minifile: 'mini_ostgulvsliping_7.jpg',
        filename: 'ostgulvsliping_7.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        before: ['ostgulvsliping_5.jpg'],
        group: [],
    },
    {
        name: 'ostgulvsliping_8.jpg',
        minifile: 'mini_ostgulvsliping_8.jpg',
        filename: 'ostgulvsliping_8.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        before: ['ostgulvsliping_6.jpg'],
        group: [],
    },
    {
        name: 'ostgulvsliping_9.jpg',
        minifile: 'mini_ostgulvsliping_9.jpg',
        filename: 'ostgulvsliping_9.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_10.jpg',
        minifile: 'mini_ostgulvsliping_10.jpg',
        filename: 'ostgulvsliping_10.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_11.jpg',
        minifile: 'mini_ostgulvsliping_11.jpg',
        filename: 'ostgulvsliping_11.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_12.jpg',
        minifile: 'mini_ostgulvsliping_12.jpg',
        filename: 'ostgulvsliping_12.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_13.jpg',
        minifile: 'mini_ostgulvsliping_13.jpg',
        filename: 'ostgulvsliping_13.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_14.jpg',
        minifile: 'mini_ostgulvsliping_14.jpg',
        filename: 'ostgulvsliping_14.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_15.jpg',
        minifile: 'mini_ostgulvsliping_15.jpg',
        filename: 'ostgulvsliping_15.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_28.jpg',
        minifile: 'mini_ostgulvsliping_28.jpg',
        filename: 'ostgulvsliping_28.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_29.jpg',
        minifile: 'mini_ostgulvsliping_29.jpg',
        filename: 'ostgulvsliping_29.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_47.jpg',
        minifile: 'mini_ostgulvsliping_47.jpg',
        filename: 'ostgulvsliping_47.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,1],
        group: ['ostgulvsliping_28.jpg'],
        before: [],
    },
    {
        name: 'ostgulvsliping_48.jpg',
        minifile: 'mini_ostgulvsliping_48.jpg',
        filename: 'ostgulvsliping_48.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_49.jpg',
        minifile: 'mini_ostgulvsliping_49.jpg',
        filename: 'ostgulvsliping_49.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_50.jpg',
        minifile: 'mini_ostgulvsliping_50.jpg',
        filename: 'ostgulvsliping_50.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_51.jpg',
        minifile: 'mini_ostgulvsliping_51.jpg',
        filename: 'ostgulvsliping_51.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_58.jpg',
            'ostgulvsliping_52.jpg',
            'ostgulvsliping_54.jpg',
            'ostgulvsliping_55.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_52.jpg',
        minifile: 'mini_ostgulvsliping_52.jpg',
        filename: 'ostgulvsliping_52.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [

            'ostgulvsliping_51.jpg',
            'ostgulvsliping_53.jpg',
            'ostgulvsliping_54.jpg',
            'ostgulvsliping_55.jpg',

        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_53.jpg',
        minifile: 'mini_ostgulvsliping_53.jpg',
        filename: 'ostgulvsliping_53.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [
            'ostgulvsliping_54.jpg',
            'ostgulvsliping_55.jpg',
            'ostgulvsliping_56.jpg',
            'ostgulvsliping_57.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_54.jpg',
        minifile: 'mini_ostgulvsliping_54.jpg',
        filename: 'ostgulvsliping_54.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [
            'ostgulvsliping_52.jpg',
            'ostgulvsliping_53.jpg',
            'ostgulvsliping_55.jpg',
            'ostgulvsliping_57.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_55.jpg',
        minifile: 'mini_ostgulvsliping_55.jpg',
        filename: 'ostgulvsliping_55.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [
            'ostgulvsliping_58.jpg',
            'ostgulvsliping_51.jpg',
            'ostgulvsliping_52.jpg',
            'ostgulvsliping_57.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_56.jpg',
        minifile: 'mini_ostgulvsliping_56.jpg',
        filename: 'ostgulvsliping_56.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [
            'ostgulvsliping_58.jpg',
            'ostgulvsliping_51.jpg',
            'ostgulvsliping_52.jpg',
            'ostgulvsliping_57.jpg',
        ],
         before: [],
    },
    {
        name: 'ostgulvsliping_57.jpg',
        minifile: 'mini_ostgulvsliping_57.jpg',
        filename: 'ostgulvsliping_57.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [
            'ostgulvsliping_53.jpg',
            'ostgulvsliping_54.jpg',
            'ostgulvsliping_55.jpg',
            'ostgulvsliping_56.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_58.jpg',
        minifile: 'mini_ostgulvsliping_58.jpg',
        filename: 'ostgulvsliping_58.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_51.jpg',
            'ostgulvsliping_52.jpg',
            'ostgulvsliping_53.jpg',
            'ostgulvsliping_54.jpg',

        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_59.jpg',
        minifile: 'mini_ostgulvsliping_59.jpg',
        filename: 'ostgulvsliping_59.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_60.jpg',
        minifile: 'mini_ostgulvsliping_60.jpg',
        filename: 'ostgulvsliping_60.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_61.jpg',
        minifile: 'mini_ostgulvsliping_61.jpg',
        filename: 'ostgulvsliping_61.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_62.jpg',
        minifile: 'mini_ostgulvsliping_62.jpg',
        filename: 'ostgulvsliping_62.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_63.jpg',
        minifile: 'mini_ostgulvsliping_63.jpg',
        filename: 'ostgulvsliping_63.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_64.jpg',
        minifile: 'mini_ostgulvsliping_64.jpg',
        filename: 'ostgulvsliping_64.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_65.jpg',
        minifile: 'mini_ostgulvsliping_65.jpg',
        filename: 'ostgulvsliping_65.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_62.jpg'],
    },
    {
        name: 'ostgulvsliping_66.jpg',
        minifile: 'mini_ostgulvsliping_66.jpg',
        filename: 'ostgulvsliping_66.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],        
        before: ['ostgulvsliping_61.jpg'],
       
    },
    {
        name: 'ostgulvsliping_67.jpg',
        minifile: 'mini_ostgulvsliping_67.jpg',
        filename: 'ostgulvsliping_67.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,1,0],
        group: [],
        before: ['ostgulvsliping_61.jpg'],
    },
    {
        name: 'ostgulvsliping_68.jpg',
        minifile: 'mini_ostgulvsliping_68.jpg',
        filename: 'ostgulvsliping_68.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_62.jpg'],
    },
    {
        name: 'ostgulvsliping_69.jpg',
        minifile: 'mini_ostgulvsliping_69.jpg',
        filename: 'ostgulvsliping_69.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_62.jpg'],
    },
    {
        name: 'ostgulvsliping_70.jpg',
        minifile: 'mini_ostgulvsliping_70.jpg',
        filename: 'ostgulvsliping_70.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_16.jpg',
        minifile: 'mini_ostgulvsliping_16.jpg',
        filename: 'ostgulvsliping_16.jpg',
        x: 50,
        y: 90,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_17.jpg',
        minifile: 'mini_ostgulvsliping_17.jpg',
        filename: 'ostgulvsliping_17.jpg',
        x: 50,
        y: 90,
        scale: 120,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_18.jpg',
        minifile: 'mini_ostgulvsliping_18.jpg',
        filename: 'ostgulvsliping_18.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_19.jpg',
        minifile: 'mini_ostgulvsliping_19.jpg',
        filename: 'ostgulvsliping_19.jpg',
        x: 50,
        y: 90,
        scale: 150,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_18.jpg'],
    },
    {
        name: 'ostgulvsliping_20.jpg',
        minifile: 'mini_ostgulvsliping_20.jpg',
        filename: 'ostgulvsliping_20.jpg',
        x: 50,  
        y: 50,
        scale: 120,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_21.jpg',
        minifile: 'mini_ostgulvsliping_21.jpg',
        filename: 'ostgulvsliping_21.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_22.jpg',
        minifile: 'mini_ostgulvsliping_22.jpg',
        filename: 'ostgulvsliping_22.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_23.jpg',
        minifile: 'mini_ostgulvsliping_23.jpg',
        filename: 'ostgulvsliping_23.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_24.jpg',
        minifile: 'mini_ostgulvsliping_24.jpg',
        filename: 'ostgulvsliping_24.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,1],
        group: [
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_25.jpg',
            'ostgulvsliping_26.jpg',
            'ostgulvsliping_27.jpg',
            'ostgulvsliping_35.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_25.jpg',
        minifile: 'mini_ostgulvsliping_25.jpg',
        filename: 'ostgulvsliping_25.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_24.jpg',
            'ostgulvsliping_26.jpg',
            'ostgulvsliping_27.jpg',
            'ostgulvsliping_35.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_26.jpg',
        minifile: 'mini_ostgulvsliping_26.jpg',
        filename: 'ostgulvsliping_26.jpg',
        x: 50,
        y: 100,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [            
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_24.jpg',
            'ostgulvsliping_25.jpg',
            'ostgulvsliping_27.jpg',
            'ostgulvsliping_35.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_27.jpg',
        minifile: 'mini_ostgulvsliping_27.jpg',
        filename: 'ostgulvsliping_27.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_24.jpg',
            'ostgulvsliping_25.jpg',
            'ostgulvsliping_26.jpg',
            'ostgulvsliping_35.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_30.jpg',
        minifile: 'mini_ostgulvsliping_30.jpg',
        filename: 'ostgulvsliping_30.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_31.jpg',
        minifile: 'mini_ostgulvsliping_31.jpg',
        filename: 'ostgulvsliping_31.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_32.jpg',
        minifile: 'mini_ostgulvsliping_32.jpg',
        filename: 'ostgulvsliping_32.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_33.jpg',
        minifile: 'mini_ostgulvsliping_33.jpg',
        filename: 'ostgulvsliping_33.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        before: ['ostgulvsliping_31.jpg'],
        group: [],
    },
    {
        name: 'ostgulvsliping_34.jpg',
        minifile: 'mini_ostgulvsliping_34.jpg',
        filename: 'ostgulvsliping_34.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        before: ['ostgulvsliping_31.jpg'],
        group: [],
    },
    {
        name: 'ostgulvsliping_35.jpg',
        minifile: 'mini_ostgulvsliping_35.jpg',
        filename: 'ostgulvsliping_35.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        before: ['ostgulvsliping_31.jpg'],
        group: [            
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_24.jpg',
            'ostgulvsliping_25.jpg',
            'ostgulvsliping_26.jpg',
            'ostgulvsliping_27.jpg',
        ],
    },
    {
        name: 'ostgulvsliping_36.jpg',
        minifile: 'mini_ostgulvsliping_36.jpg',
        filename: 'ostgulvsliping_36.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_37.jpg',
        minifile: 'mini_ostgulvsliping_37.jpg',
        filename: 'ostgulvsliping_37.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_32.jpg']
    },
    {
        name: 'ostgulvsliping_38.jpg',
        minifile: 'mini_ostgulvsliping_38.jpg',
        filename: 'ostgulvsliping_38.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_46.jpg',
            'ostgulvsliping_43.jpg',
            'ostgulvsliping_44.jpg',
            'ostgulvsliping_45.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_39.jpg',
        minifile: 'mini_ostgulvsliping_39.jpg',
        filename: 'ostgulvsliping_39.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_37.jpg',
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_35.jpg',
            'ostgulvsliping_36.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_40.jpg',
        minifile: 'mini_ostgulvsliping_40.jpg',
        filename: 'ostgulvsliping_40.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_41.jpg',
        minifile: 'mini_ostgulvsliping_41.jpg',
        filename: 'ostgulvsliping_41.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_46.jpg',
            'ostgulvsliping_43.jpg',
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_35.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_42.jpg',
        minifile: 'mini_ostgulvsliping_42.jpg',
        filename: 'ostgulvsliping_42.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_45.jpg',
            'ostgulvsliping_44.jpg',
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_35.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_43.jpg',
        minifile: 'mini_ostgulvsliping_43.jpg',
        filename: 'ostgulvsliping_43.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_35.jpg',
            'ostgulvsliping_44.jpg',
            'ostgulvsliping_45.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_44.jpg',
        minifile: 'mini_ostgulvsliping_44.jpg',
        filename: 'ostgulvsliping_44.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_35.jpg',
            'ostgulvsliping_42.jpg',
            'ostgulvsliping_45.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_45.jpg',
        minifile: 'mini_ostgulvsliping_45.jpg',
        filename: 'ostgulvsliping_45.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_35.jpg',
            'ostgulvsliping_42.jpg',
            'ostgulvsliping_45.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_46.jpg',
        minifile: 'mini_ostgulvsliping_46.jpg',
        filename: 'ostgulvsliping_46.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [
            'ostgulvsliping_38.jpg',
            'ostgulvsliping_35.jpg',
            'ostgulvsliping_42.jpg',
            'ostgulvsliping_45.jpg',
        ],
        before: [],
    },
    {
        name: 'ostgulvsliping_71.jpg',
        minifile: 'mini_ostgulvsliping_71.jpg',
        filename: 'ostgulvsliping_71.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },   
    {
        name: 'ostgulvsliping_72.jpg',
        minifile: 'mini_ostgulvsliping_72.jpg',
        filename: 'ostgulvsliping_72.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,1],
        group: [],
        before: [],
    }, 
    {
        name: 'ostgulvsliping_73.jpg',
        minifile: 'mini_ostgulvsliping_73.jpg',
        filename: 'ostgulvsliping_73.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,1],
        group: [],
        before: [],
    }, 
    {
        name: 'ostgulvsliping_74.jpg',
        minifile: 'mini_ostgulvsliping_74.jpg',
        filename: 'ostgulvsliping_74.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_75.jpg',
        minifile: 'mini_ostgulvsliping_75.jpg',
        filename: 'ostgulvsliping_75.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_76.jpg',
        minifile: 'mini_ostgulvsliping_76.jpg',
        filename: 'ostgulvsliping_76.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_77.jpg',
        minifile: 'mini_ostgulvsliping_77.jpg',
        filename: 'ostgulvsliping_77.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_78.jpg',
        minifile: 'mini_ostgulvsliping_78.jpg',
        filename: 'ostgulvsliping_78.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_79.jpg',
        minifile: 'mini_ostgulvsliping_79.jpg',
        filename: 'ostgulvsliping_79.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_80.jpg',
        minifile: 'mini_ostgulvsliping_80.jpg',
        filename: 'ostgulvsliping_80.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_81.jpg',
        minifile: 'mini_ostgulvsliping_81.jpg',
        filename: 'ostgulvsliping_81.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_82.jpg',
        minifile: 'mini_ostgulvsliping_82.jpg',
        filename: 'ostgulvsliping_82.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_83.jpg',
        minifile: 'mini_ostgulvsliping_83.jpg',
        filename: 'ostgulvsliping_83.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_84.jpg',
        minifile: 'mini_ostgulvsliping_84.jpg',
        filename: 'ostgulvsliping_84.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_85.jpg',
        minifile: 'mini_ostgulvsliping_85.jpg',
        filename: 'ostgulvsliping_85.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_86.jpg',
        minifile: 'mini_ostgulvsliping_86.jpg',
        filename: 'ostgulvsliping_86.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_87.jpg',
        minifile: 'mini_ostgulvsliping_87.jpg',
        filename: 'ostgulvsliping_87.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_88.jpg',
        minifile: 'mini_ostgulvsliping_88.jpg',
        filename: 'ostgulvsliping_88.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_89.jpg',
        minifile: 'mini_ostgulvsliping_89.jpg',
        filename: 'ostgulvsliping_89.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_90.jpg',
        minifile: 'mini_ostgulvsliping_90.jpg',
        filename: 'ostgulvsliping_90.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_91.jpg',
        minifile: 'mini_ostgulvsliping_91.jpg',
        filename: 'ostgulvsliping_91.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_92.jpg',
        minifile: 'mini_ostgulvsliping_92.jpg',
        filename: 'ostgulvsliping_92.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_93.jpg',
        minifile: 'mini_ostgulvsliping_93.jpg',
        filename: 'ostgulvsliping_93.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_94.jpg',
        minifile: 'mini_ostgulvsliping_94.jpg',
        filename: 'ostgulvsliping_94.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_95.jpg',
        minifile: 'mini_ostgulvsliping_95.jpg',
        filename: 'ostgulvsliping_95.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_96.jpg',
        minifile: 'mini_ostgulvsliping_96.jpg',
        filename: 'ostgulvsliping_96.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_97.jpg',
        minifile: 'mini_ostgulvsliping_97.jpg',
        filename: 'ostgulvsliping_97.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,1],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_98.jpg',
        minifile: 'mini_ostgulvsliping_98.jpg',
        filename: 'ostgulvsliping_98.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_99.jpg',
        minifile: 'mini_ostgulvsliping_99.jpg',
        filename: 'ostgulvsliping_99.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_100.jpg',
        minifile: 'mini_ostgulvsliping_100.jpg',
        filename: 'ostgulvsliping_100.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_101.jpg',
        minifile: 'mini_ostgulvsliping_101.jpg',
        filename: 'ostgulvsliping_101.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_102.jpg',
        minifile: 'mini_ostgulvsliping_102.jpg',
        filename: 'ostgulvsliping_102.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_103.jpg',
        minifile: 'mini_ostgulvsliping_103.jpg',
        filename: 'ostgulvsliping_103.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_104.jpg',
        minifile: 'mini_ostgulvsliping_104.jpg',
        filename: 'ostgulvsliping_104.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_105.jpg',
        minifile: 'mini_ostgulvsliping_105.jpg',
        filename: 'ostgulvsliping_105.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_106.jpg',
        minifile: 'mini_ostgulvsliping_106.jpg',
        filename: 'ostgulvsliping_106.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_107.jpg',
        minifile: 'mini_ostgulvsliping_107.jpg',
        filename: 'ostgulvsliping_107.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_108.jpg',
        minifile: 'mini_ostgulvsliping_108.jpg',
        filename: 'ostgulvsliping_108.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_109.jpg',
        minifile: 'mini_ostgulvsliping_109.jpg',
        filename: 'ostgulvsliping_109.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_107.jpg'],
    },
    {
        name: 'ostgulvsliping_110.jpg',
        minifile: 'mini_ostgulvsliping_110.jpg',
        filename: 'ostgulvsliping_110.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_107.jpg'],
    },
    {
        name: 'ostgulvsliping_111.jpg',
        minifile: 'mini_ostgulvsliping_111.jpg',
        filename: 'ostgulvsliping_111.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_107.jpg'],
    },
    {
        name: 'ostgulvsliping_112.jpg',
        minifile: 'mini_ostgulvsliping_112.jpg',
        filename: 'ostgulvsliping_112.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_107.jpg'],
    },
    {
        name: 'ostgulvsliping_113.jpg',
        minifile: 'mini_ostgulvsliping_113.jpg',
        filename: 'ostgulvsliping_113.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_107.jpg'],
    },
    {
        name: 'ostgulvsliping_114.jpg',
        minifile: 'mini_ostgulvsliping_114.jpg',
        filename: 'ostgulvsliping_114.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: ['ostgulvsliping_107.jpg'],
    },
    {
        name: 'ostgulvsliping_115.jpg',
        minifile: 'mini_ostgulvsliping_115.jpg',
        filename: 'ostgulvsliping_115.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_107.jpg'],
    },
    {
        name: 'ostgulvsliping_116.jpg',
        minifile: 'mini_ostgulvsliping_116.jpg',
        filename: 'ostgulvsliping_116.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_117.jpg',
        minifile: 'mini_ostgulvsliping_117.jpg',
        filename: 'ostgulvsliping_117.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_118.jpg',
        minifile: 'mini_ostgulvsliping_118.jpg',
        filename: 'ostgulvsliping_118.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_119.jpg',
        minifile: 'mini_ostgulvsliping_119.jpg',
        filename: 'ostgulvsliping_119.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_120.jpg',
        minifile: 'mini_ostgulvsliping_120.jpg',
        filename: 'ostgulvsliping_120.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_121.jpg',
        minifile: 'mini_ostgulvsliping_121.jpg',
        filename: 'ostgulvsliping_121.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_122.jpg',
        minifile: 'mini_ostgulvsliping_122.jpg',
        filename: 'ostgulvsliping_122.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_123.jpg',
        minifile: 'mini_ostgulvsliping_123.jpg',
        filename: 'ostgulvsliping_123.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,1,0],
        group: [],
        before: ['ostgulvsliping_117.jpg'],
    },
    {
        name: 'ostgulvsliping_124.jpg',
        minifile: 'mini_ostgulvsliping_124.jpg',
        filename: 'ostgulvsliping_124.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_125.jpg',
        minifile: 'mini_ostgulvsliping_125.jpg',
        filename: 'ostgulvsliping_125.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_126.jpg',
        minifile: 'mini_ostgulvsliping_126.jpg',
        filename: 'ostgulvsliping_126.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_127.jpg',
        minifile: 'mini_ostgulvsliping_127.jpg',
        filename: 'ostgulvsliping_127.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: ['ostgulvsliping_122.jpg'],
    },
    {
        name: 'ostgulvsliping_128.jpg',
        minifile: 'mini_ostgulvsliping_128.jpg',
        filename: 'ostgulvsliping_128.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_117.jpg'],
    },
    {
        name: 'ostgulvsliping_129.jpg',
        minifile: 'mini_ostgulvsliping_129.jpg',
        filename: 'ostgulvsliping_129.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_130.jpg',
        minifile: 'mini_ostgulvsliping_130.jpg',
        filename: 'ostgulvsliping_130.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_131.jpg',
        minifile: 'mini_ostgulvsliping_131.jpg',
        filename: 'ostgulvsliping_131.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_132.jpg',
        minifile: 'mini_ostgulvsliping_132.jpg',
        filename: 'ostgulvsliping_132.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_133.jpg',
        minifile: 'mini_ostgulvsliping_133.jpg',
        filename: 'ostgulvsliping_133.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_134.jpg',
        minifile: 'mini_ostgulvsliping_134.jpg',
        filename: 'ostgulvsliping_134.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_135.jpg',
        minifile: 'mini_ostgulvsliping_135.jpg',
        filename: 'ostgulvsliping_135.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_136.jpg',
        minifile: 'mini_ostgulvsliping_136.jpg',
        filename: 'ostgulvsliping_136.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_137.jpg',
        minifile: 'mini_ostgulvsliping_137.jpg',
        filename: 'ostgulvsliping_137.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_138.jpg',
        minifile: 'mini_ostgulvsliping_138.jpg',
        filename: 'ostgulvsliping_138.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_139.jpg',
        minifile: 'mini_ostgulvsliping_139.jpg',
        filename: 'ostgulvsliping_139.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_140.jpg',
        minifile: 'mini_ostgulvsliping_140.jpg',
        filename: 'ostgulvsliping_140.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_141.jpg',
        minifile: 'mini_ostgulvsliping_141.jpg',
        filename: 'ostgulvsliping_141.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_142.jpg',
        minifile: 'mini_ostgulvsliping_142.jpg',
        filename: 'ostgulvsliping_142.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_143.jpg',
        minifile: 'mini_ostgulvsliping_143.jpg',
        filename: 'ostgulvsliping_143.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_144.jpg',
        minifile: 'mini_ostgulvsliping_144.jpg',
        filename: 'ostgulvsliping_144.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_145.jpg',
        minifile: 'mini_ostgulvsliping_145.jpg',
        filename: 'ostgulvsliping_145.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_146.jpg',
        minifile: 'mini_ostgulvsliping_146.jpg',
        filename: 'ostgulvsliping_146.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_147.jpg',
        minifile: 'mini_ostgulvsliping_147.jpg',
        filename: 'ostgulvsliping_147.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_148.jpg',
        minifile: 'mini_ostgulvsliping_148.jpg',
        filename: 'ostgulvsliping_148.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_149.jpg',
        minifile: 'mini_ostgulvsliping_149.jpg',
        filename: 'ostgulvsliping_149.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_150.jpg',
        minifile: 'mini_ostgulvsliping_150.jpg',
        filename: 'ostgulvsliping_150.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_131.jpg'],
    },
    {
        name: 'ostgulvsliping_151.jpg',
        minifile: 'mini_ostgulvsliping_151.jpg',
        filename: 'ostgulvsliping_151.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_152.jpg',
        minifile: 'mini_ostgulvsliping_152.jpg',
        filename: 'ostgulvsliping_152.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_153.jpg',
        minifile: 'mini_ostgulvsliping_153.jpg',
        filename: 'ostgulvsliping_153.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_154.jpg',
        minifile: 'mini_ostgulvsliping_154.jpg',
        filename: 'ostgulvsliping_154.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_131.jpg'],
    },
    {
        name: 'ostgulvsliping_155.jpg',
        minifile: 'mini_ostgulvsliping_155.jpg',
        filename: 'ostgulvsliping_155.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_133.jpg'],
    },
    {
        name: 'ostgulvsliping_156.jpg',
        minifile: 'mini_ostgulvsliping_156.jpg   ',
        filename: 'ostgulvsliping_156.jpg ',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_132.jpg'],
    },
    {
        name: 'ostgulvsliping_157.jpg',
        minifile: 'mini_ostgulvsliping_157.jpg',
        filename: 'ostgulvsliping_157.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_158.jpg',
        minifile: 'mini_ostgulvsliping_158.jpg',
        filename: 'ostgulvsliping_158.jpg',
        x: 50,
        y: 80,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_159.jpg',
        minifile: 'mini_ostgulvsliping_159.jpg',
        filename: 'ostgulvsliping_159.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_160.jpg',
        minifile: 'mini_ostgulvsliping_160.jpg',
        filename: 'ostgulvsliping_160.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_161.jpg',
        minifile: 'mini_ostgulvsliping_161.jpg',
        filename: 'ostgulvsliping_161.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_162.jpg',
        minifile: 'mini_ostgulvsliping_162.jpg',
        filename: 'ostgulvsliping_162.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_163.jpg',
        minifile: 'mini_ostgulvsliping_163.jpg',
        filename: 'ostgulvsliping_163.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_164.jpg',
        minifile: 'mini_ostgulvsliping_164.jpg',
        filename: 'ostgulvsliping_164.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_165.jpg',
        minifile: 'mini_ostgulvsliping_165.jpg',
        filename: 'ostgulvsliping_165.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_166.jpg',
        minifile: 'mini_ostgulvsliping_166.jpg',
        filename: 'ostgulvsliping_166.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_167.jpg',
        minifile: 'mini_ostgulvsliping_167.jpg',
        filename: 'ostgulvsliping_167.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_168.jpg',
        minifile: 'mini_ostgulvsliping_168.jpg',
        filename: 'ostgulvsliping_168.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_169.jpg',
        minifile: 'mini_ostgulvsliping_169.jpg',
        filename: 'ostgulvsliping_169.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_170.jpg',
        minifile: 'mini_ostgulvsliping_170.jpg',
        filename: 'ostgulvsliping_170.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_171.jpg',
        minifile: 'mini_ostgulvsliping_171.jpg',
        filename: 'ostgulvsliping_171.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_172.jpg',
        minifile: 'mini_ostgulvsliping_172.jpg',
        filename: 'ostgulvsliping_172.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_173.jpg',
        minifile: 'mini_ostgulvsliping_173.jpg',
        filename: 'ostgulvsliping_173.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_174.jpg',
        minifile: 'mini_ostgulvsliping_174.jpg',
        filename: 'ostgulvsliping_174.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_175.jpg',
        minifile: 'mini_ostgulvsliping_175.jpg',
        filename: 'ostgulvsliping_175.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_176.jpg',
        minifile: 'mini_ostgulvsliping_176.jpg',
        filename: 'ostgulvsliping_176.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_177.jpg',
        minifile: 'mini_ostgulvsliping_177.jpg',
        filename: 'ostgulvsliping_177.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_178.jpg',
        minifile: 'mini_ostgulvsliping_178.jpg',
        filename: 'ostgulvsliping_178.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },    
    {
        name: 'ostgulvsliping_179.jpg',
        minifile: 'mini_ostgulvsliping_179.jpg',
        filename: 'ostgulvsliping_179.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_180.jpg',
        minifile: 'mini_ostgulvsliping_180.jpg',
        filename: 'ostgulvsliping_180.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_181.jpg',
        minifile: 'mini_ostgulvsliping_181.jpg',
        filename: 'ostgulvsliping_181.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_182.jpg',
        minifile: 'mini_ostgulvsliping_182.jpg',
        filename: 'ostgulvsliping_182.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_183.jpg',
        minifile: 'mini_ostgulvsliping_183.jpg',
        filename: 'ostgulvsliping_183.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_184.jpg',
        minifile: 'mini_ostgulvsliping_184.jpg',
        filename: 'ostgulvsliping_184.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_185.jpg',
        minifile: 'mini_ostgulvsliping_185.jpg',
        filename: 'ostgulvsliping_185.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_186.jpg',
        minifile: 'mini_ostgulvsliping_186.jpg',
        filename: 'ostgulvsliping_186.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },    {
        name: 'ostgulvsliping_187.jpg',
        minifile: 'mini_ostgulvsliping_187.jpg',
        filename: 'ostgulvsliping_187.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_188.jpg',
        minifile: 'mini_ostgulvsliping_188.jpg',
        filename: 'ostgulvsliping_188.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_189.jpg',
        minifile: 'mini_ostgulvsliping_189.jpg',
        filename: 'ostgulvsliping_189.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_190.jpg',
        minifile: 'mini_ostgulvsliping_190.jpg',
        filename: 'ostgulvsliping_190.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_191.jpg',
        minifile: 'mini_ostgulvsliping_191.jpg',
        filename: 'ostgulvsliping_191.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_192.jpg',
        minifile: 'mini_ostgulvsliping_192.jpg',
        filename: 'ostgulvsliping_192.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_193.jpg',
        minifile: 'mini_ostgulvsliping_193.jpg',
        filename: 'ostgulvsliping_193.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_194.jpg',
        minifile: 'mini_ostgulvsliping_194.jpg',
        filename: 'ostgulvsliping_194.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_195.jpg',
        minifile: 'mini_ostgulvsliping_195.jpg',
        filename: 'ostgulvsliping_195.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_196.jpg',
        minifile: 'mini_ostgulvsliping_196.jpg',
        filename: 'ostgulvsliping_196.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_197.jpg',
        minifile: 'mini_ostgulvsliping_197.jpg',
        filename: 'ostgulvsliping_197.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_198.jpg',
        minifile: 'mini_ostgulvsliping_198.jpg',
        filename: 'ostgulvsliping_198.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_199.jpg',
        minifile: 'mini_ostgulvsliping_199.jpg',
        filename: 'ostgulvsliping_199.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_200.jpg',
        minifile: 'mini_ostgulvsliping_200.jpg',
        filename: 'ostgulvsliping_200.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_201.jpg',
        minifile: 'mini_ostgulvsliping_201.jpg',
        filename: 'ostgulvsliping_201.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_202.jpg',
        minifile: 'mini_ostgulvsliping_202.jpg',
        filename: 'ostgulvsliping_202.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_203.jpg',
        minifile: 'mini_ostgulvsliping_203.jpg',
        filename: 'ostgulvsliping_203.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_204.jpg',
        minifile: 'mini_ostgulvsliping_204.jpg',
        filename: 'ostgulvsliping_204.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_205.jpg',
        minifile: 'mini_ostgulvsliping_205.jpg',
        filename: 'ostgulvsliping_205.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: ['ostgulvsliping_200.jpg'],
    },
    {
        name: 'ostgulvsliping_206.jpg',
        minifile: 'mini_ostgulvsliping_206.jpg',
        filename: 'ostgulvsliping_206.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_207.jpg',
        minifile: 'mini_ostgulvsliping_207.jpg',
        filename: 'ostgulvsliping_207.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_208.jpg',
        minifile: 'mini_ostgulvsliping_208.jpg',
        filename: 'ostgulvsliping_208.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: ['ostgulvsliping_203.jpg'],
    },
    {
        name: 'ostgulvsliping_209.jpg',
        minifile: 'mini_ostgulvsliping_209.jpg',
        filename: 'ostgulvsliping_209.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_204.jpg'],
    },    
    {
        name: 'ostgulvsliping_210.jpg',
        minifile: 'mini_ostgulvsliping_210.jpg',
        filename: 'ostgulvsliping_210.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: ['ostgulvsliping_204.jpg'],
    },
    {
        name: 'ostgulvsliping_211.jpg',
        minifile: 'mini_ostgulvsliping_211.jpg',
        filename: 'ostgulvsliping_211.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_212.jpg',
        minifile: 'mini_ostgulvsliping_212.jpg',
        filename: 'ostgulvsliping_212.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_213.jpg',
        minifile: 'mini_ostgulvsliping_213.jpg',
        filename: 'ostgulvsliping_213.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_214.jpg',
        minifile: 'mini_ostgulvsliping_214.jpg',
        filename: 'ostgulvsliping_214.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_215.jpg',
        minifile: 'mini_ostgulvsliping_215.jpg',
        filename: 'ostgulvsliping_215.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: ['ostgulvsliping_206.jpg'],
    },
    {
        name: 'ostgulvsliping_216.jpg',
        minifile: 'mini_ostgulvsliping_216.jpg',
        filename: 'ostgulvsliping_216.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: ['ostgulvsliping_207.jpg'],
    },
    {
        name: 'ostgulvsliping_217.jpg',
        minifile: 'mini_ostgulvsliping_217.jpg',
        filename: 'ostgulvsliping_217.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: ['ostgulvsliping_207.jpg'],
    },    
    {
        name: 'ostgulvsliping_218.jpg',
        minifile: 'mini_ostgulvsliping_218.jpg',
        filename: 'ostgulvsliping_218.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_219.jpg',
        minifile: 'mini_ostgulvsliping_219.jpg',
        filename: 'ostgulvsliping_219.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_220.jpg',
        minifile: 'mini_ostgulvsliping_220.jpg',
        filename: 'ostgulvsliping_220.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_221.jpg',
        minifile: 'mini_ostgulvsliping_221.jpg',
        filename: 'ostgulvsliping_221.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_222.jpg',
        minifile: 'mini_ostgulvsliping_222.jpg',
        filename: 'ostgulvsliping_222.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_220.jpg'],
    },
    {
        name: 'ostgulvsliping_223.jpg',
        minifile: 'mini_ostgulvsliping_223.jpg',
        filename: 'ostgulvsliping_223.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,1,0,0],
        group: [],
        before: ['ostgulvsliping_220.jpg'],
    },
    {
        name: 'ostgulvsliping_224.jpg',
        minifile: 'mini_ostgulvsliping_224.jpg',
        filename: 'ostgulvsliping_224.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_225.jpg',
        minifile: 'mini_ostgulvsliping_225.jpg',
        filename: 'ostgulvsliping_225.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_226.jpg',
        minifile: 'mini_ostgulvsliping_226.jpg',
        filename: 'ostgulvsliping_226.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_227.jpg',
        minifile: 'mini_ostgulvsliping_227.jpg',
        filename: 'ostgulvsliping_227.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_228.jpg',
        minifile: 'mini_ostgulvsliping_228.jpg',
        filename: 'ostgulvsliping_228.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_229.jpg',
        minifile: 'mini_ostgulvsliping_229.jpg',
        filename: 'ostgulvsliping_229.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_224.jpg'],
    },
    {
        name: 'ostgulvsliping_230.jpg',
        minifile: 'mini_ostgulvsliping_230.jpg',
        filename: 'ostgulvsliping_230.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_231.jpg',
        minifile: 'mini_ostgulvsliping_231.jpg',
        filename: 'ostgulvsliping_231.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: ['ostgulvsliping_230.jpg'],
    },
    {
        name: 'ostgulvsliping_232.jpg',
        minifile: 'mini_ostgulvsliping_232.jpg',
        filename: 'ostgulvsliping_232.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_233.jpg',
        minifile: 'mini_ostgulvsliping_233.jpg',
        filename: 'ostgulvsliping_233.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_234.jpg',
        minifile: 'mini_ostgulvsliping_234.jpg',
        filename: 'ostgulvsliping_234.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_235.jpg',
        minifile: 'mini_ostgulvsliping_235.jpg',
        filename: 'ostgulvsliping_235.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_236.jpg',
        minifile: 'mini_ostgulvsliping_236.jpg',
        filename: 'ostgulvsliping_236.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,1,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_237.jpg',
        minifile: 'mini_ostgulvsliping_237.jpg',
        filename: 'ostgulvsliping_237.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_234.jpg'],
    },
    {
        name: 'ostgulvsliping_238.jpg',
        minifile: 'mini_ostgulvsliping_238.jpg',
        filename: 'ostgulvsliping_238.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: ['ostgulvsliping_235.jpg'],
    },
    {
        name: 'ostgulvsliping_239.jpg',
        minifile: 'mini_ostgulvsliping_239.jpg',
        filename: 'ostgulvsliping_239.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },    
    {
        name: 'ostgulvsliping_240.jpg',
        minifile: 'mini_ostgulvsliping_240.jpg',
        filename: 'ostgulvsliping_240.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_241.jpg',
        minifile: 'mini_ostgulvsliping_241.jpg',
        filename: 'ostgulvsliping_241.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_242.jpg',
        minifile: 'mini_ostgulvsliping_242.jpg',
        filename: 'ostgulvsliping_242.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_243.jpg',
        minifile: 'mini_ostgulvsliping_243.jpg',
        filename: 'ostgulvsliping_243.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_244.jpg',
        minifile: 'mini_ostgulvsliping_244.jpg',
        filename: 'ostgulvsliping_244.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_245.jpg',
        minifile: 'mini_ostgulvsliping_245.jpg',
        filename: 'ostgulvsliping_245.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,0,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_246.jpg',
        minifile: 'mini_ostgulvsliping_246.jpg',
        filename: 'ostgulvsliping_246.jpg',
        x: 50,
        y: 50,
        scale: 105,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,0,0,0,1,0],
        group: [],
        before: [],
    },
    {
        name: 'ostgulvsliping_247.jpg',
        minifile: 'mini_ostgulvsliping_247.jpg',
        filename: 'ostgulvsliping_247.jpg',
        x: 50,
        y: 50,
        scale: 180,
        description: ['','',''],
        tags: ['','','',''],
        category: [0,1,0,0,1,0],
        group: [],
        before: [],
    },    
];

export {imgsArray};