import React, { Component } from 'react';
import Lightbox from 'react-images';
import PropTypes from 'prop-types';

class PicturesGallery extends Component {
    constructor(props){
    super(props);
    this.state = { currentImage: 0, lightboxIsOpen: false}; 
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
      this.nextImage = this.nextImage.bind(this, this.state.currentImage);
      this.previousImage = this.previousImage.bind(this);
    }

  openLightbox(event) {
    let id = parseInt(event.target.id);
    this.setState({
      currentImage: id,
      lightboxIsOpen: true,
    });     
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });    
  }

  previousImage() {
    let currentImage = this.state.currentImage;
    currentImage--;
    this.setState({
      currentImage    
    });     
  }

  nextImage() {
    let currentImage = this.state.currentImage;
    currentImage++;
    this.setState({
      currentImage
    }); 
  }

	render() {
		let caption = this.props.title;
    const folderName = this.props.folderName ? this.props.folderName : 'herringbone';
		const imageArray = this.props.photos ? this.props.photos : new Array();
		const imageArrayRepresentation = imageArray.map((miniature, imageIndex)=>(
          <div id={'about'+imageIndex} key={imageIndex} className='about-us'>
            <a href={'https://'+miniature.link} target='_blank'><img
            src={'./'+folderName+'/'+miniature.file+'_logo.svg'}
            className="miniature-logo"
            id={miniature.class+'logo'}
            key={imageIndex}
            alt={caption}/>
            </a>
          </div>
        ))

		return (
			<div>

				<div className="thumbnail">
				  {imageArrayRepresentation}		
				</div>
        <Lightbox
          images={imageArray.map(function(miniature) {
		        return {src: folderName+'/'+miniature.file+'.png', caption: caption}
		      })}
	        currentImage ={this.state.currentImage}
	        isOpen={this.state.lightboxIsOpen}
	        onClickPrev={this.previousImage}
	        onClickNext={this.nextImage}
	        onClose={this.closeLightbox}
	        imageCountSeparator=" / "
	        rightArrowTitle="NEXT"
      		leftArrowTitle="BACK"
      		closeButtonTitle="CLOSE"
      		backdropClosesModal				
		    />
			</div>
			);
	}
}

/*PicturesGallery.propTypes ={
  photos: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  folderName: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string ]),
}*/

export default PicturesGallery;