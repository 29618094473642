import React, { Component } from 'react';
import './popup.css';
import { getClientData } from './DataModelEngine';

class CallPopup extends Component {
	render() {
		return (
			<div className='mail-popup'>
				<a href={'mailto:'+getClientData('clientEmail')}>
                    <div className='envelope' style={
                        {

                            backgroundRepeat: 'no-repeat',
                            width: "100%",
                            height: "100%",

                        }
                    }></div>
                </a>

			</div>
		) 
	}
}

export default CallPopup;