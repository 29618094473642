const searchForBookedDays = (bookingsArray, month) => {
  	let output = {
  		days: [],
  		bookingIds: [],
  		bookingTypes: []
  	};
  	for (let i=0; i<bookingsArray.length; i++)
  	{ 
  		const bookingId = bookingsArray[i].bookingId;
	  	const bookingType = bookingsArray[i].bookingType;
  		let guestStay = (bookingsArray[i].checkout - bookingsArray[i].checkin) / (1000*60*60*24);
			for (let j=0; j<=guestStay; j++)
  		{
  			let stayDay = new Date(bookingsArray[i].checkin);
  			stayDay.setDate(bookingsArray[i].checkin.getDate() + j);
  			let day;
 				if (stayDay.getMonth() === month)
				{
					day = stayDay.getDate();	
					output.days = output.days.concat(day);
					output.bookingIds = output.bookingIds.concat(bookingId);
					output.bookingTypes = output.bookingTypes.concat(bookingType);
				}
			}				
  	}
	return output;
}

const operateOnNewBooking = (month, year, newBookingObject) => {
	let daysArray = []; //ten array musi być 12 x [] w środku zawierający poszczególne miesiące dasz rade
	let checkInDate = new Date (newBookingObject.checkin.year, newBookingObject.checkin.month, newBookingObject.checkin.day);
	if (newBookingObject.checkout.day)
	{
		let checkOutDate = new Date (newBookingObject.checkout.year, newBookingObject.checkout.month, newBookingObject.checkout.day);
		let stayLength = (checkOutDate - checkInDate) / (1000*60*60*24);
		for (let i=0; i<=stayLength; i++)
  	{
			let stayDay = new Date(checkInDate);
			stayDay.setDate(checkInDate.getDate() + i);
			if (parseInt(month) === parseInt(stayDay.getMonth()))
				daysArray[i] = String(stayDay.getDate()+'/'+stayDay.getMonth()+'/'+stayDay.getFullYear());
		
			//let month = stayDay.getMonth(); 
			//monthArray[i] = month;
		}

	  
	}
	else
	{
		daysArray[0] =  String(newBookingObject.checkin.day+'/'+newBookingObject.checkin.month+'/'+newBookingObject.checkin.year);
	}
	return daysArray;
}

const generateCalendar = (month, year, bookings, newBookingObject) =>
{
	let calendarStartDayofWeek = new Date(year, month).getDay();
	let firstCalendarDay = calendarStartDayofWeek === 0  ? 7 : calendarStartDayofWeek;
	let bookedArray;
	let newBooking = [];
	if (bookings && typeof bookings === 'object')
	{
		bookedArray = searchForBookedDays(bookings, month);
	}
	if (typeof newBookingObject === 'object' && newBookingObject.checkin.day)
	{
		newBooking = operateOnNewBooking(month, year, newBookingObject);
	}

	firstCalendarDay = firstCalendarDay === 0  ? 7 : firstCalendarDay;
	let date = 1;
	let daysInMonth = 32 - new Date(year, month, 32).getDate();
	let newCalendar = {
		daysCalendar: [],
		idsCalendar: [],
		typesCalendar: []
	};

	for (let i = 0; i < 6; i++) 
	{
    //create array with week of Month
    let daysWeek = [],
    	idsWeek = [],
    	typesWeek = [];

    //fill week with days
    for (let j = 1; j <= 7; j++) 
    {
    	//fill first week with 0 until meet firstCalendarDay of a Month
      if (i === 0 && j < firstCalendarDay) 
      {
        let day = 0;
        daysWeek.push(day);
        idsWeek.push(day);
        typesWeek.push(day);
      }
      else if (date > daysInMonth) 
      {
      	//if no more days in Month break
        break;
      }
      else 
      {
        let day;
        let valueForSearch = date +'/'+ month+'/'+ year;
       
	      if (typeof bookedArray !== 'undefined' && bookedArray.days.includes(date) && !newBooking.includes(valueForSearch))
    		{
    			//if this day is booked
    			let position = bookedArray.days.indexOf(date);
    			day = "X";
    			daysWeek.push(day);
    			idsWeek.push(bookedArray.bookingIds[position]);
        	typesWeek.push(bookedArray.bookingTypes[position]);

    		}
    		else if (newBooking && newBooking.includes(valueForSearch) && typeof bookedArray !== 'undefined')
    		{	
    			
    			if (newBooking.includes(valueForSearch) && !bookedArray.days.includes(date))
    			{
    			//new booking mark
    			day = "O";
    			daysWeek.push(day);
    			idsWeek.push("new booking");
        	typesWeek.push("new booking");	
    			}
    			else
    			{
    				return generateCalendar(month, year, bookings);
    			}
    		}
    		else
    		{
    			day = date;
    			daysWeek.push(day);
    			idsWeek.push(0);
        	typesWeek.push(0);
        }
	      date++;
      }
    }
      newCalendar.daysCalendar.push(daysWeek);
      newCalendar.idsCalendar.push(idsWeek);
      newCalendar.typesCalendar.push(typesWeek);
  }
  return newCalendar;
}


export {generateCalendar};