import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayCalendar from './DayCalendar';

class WeekCalendar extends Component {
	render() {
		return (
			<tr>
				{this.props.week.map((day, index)=>(
					<DayCalendar day={day} key={index} 
						bookingId={this.props.weekBookingIds[index]} 
						bookingType={this.props.weekBookingTypes[index]} 
						bookingTypeHeaders={this.props.bookingTypeHeaders}
						currentMonth={this.props.currentMonth} 
						currentYear={this.props.currentYear}   
						changeShowBookingDetailsValue={this.props.changeShowBookingDetailsValue}
						createBookingList={this.props.createBookingList}
						dayCellColor={
							this.props.weekBookingTypes[index] !== 0 
							? "btn btn-"+this.props.bookingTypeColors[this.props.weekBookingTypes[index]] 
							: undefined}
						newBooking={this.props.newBooking}
						roomId={this.props.roomId}
						returnSelectedDay={this.props.returnSelectedDay}
					/>
				))}
			</tr>
		)
	}
}

WeekCalendar.propTypes ={
	week: PropTypes.array.isRequired,
	weekBookingsIds: PropTypes.array,
	weekBookingsTypes: PropTypes.array,
}

export default WeekCalendar;