import React, { Component } from 'react';
import './glitch.css';

class Preface extends Component {
	constructor () {
        super();
        this.state = {
            menu: [],
            hover: '',
            animations: [],
            animationsBackup: [],
            lengths: [],
            selected: '',
            step: [0,0,0,0,0],
        }

    }
    selectMenuItem = (selected) => {
        let {showFullPage} = this.props;
        showFullPage(selected)
        const inquiry = {uid: 'LC333', selected};
        const address = {
            local: 'http://127.0.0.1:3003/api/lc/usagereport', 
            global: 'https://laskowskicreative.no/api/lc/usagereport'}
        fetch(address.global, {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inquiry)
        })
    }


componentDidMount(){
        const view = this.props.view ? this.props.view : '';
		const siteContent = this.props.siteContent ? this.props.siteContent : [];
             this.setState({
                selected: view,
            })	

    }



    
	render() {
        const viewArray = ["CodePage", "MarketingPage", "GraphicDesign", "FotoPage"];
		const view = this.props.view ? this.props.view : '';
        const viewPosition = viewArray.indexOf(view);
		const siteContent = this.props.siteContent ? this.props.siteContent : [];
		//const content = siteContent.filter(page => page.link === view);
		return (
		<div className='preface'>
        {siteContent.map((content, index)=> (
            <div key={index} id={content.link} name={content.link} onClick={()=>{this.selectMenuItem(content.link)}}>		
                {//<h1>{content.title}</h1>
                }
                       <div id='menu-popup-ico' style={{zIndex: '1'}}  onClick={()=>{this.selectMenuItem(content.link)}}>
                            <div id={'menu-ico-'+index} className='menu-ico'></div>
                        </div>   
                  <p name={content.link} onClick={this.reportUsage}>{content.title}</p>
            </div>
        ))			
		}
		</div>
		) 
	}
}

export default Preface;