import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NavigationCalendar extends Component {
	constructor (props) {
		super(props);
		this.navigation = this.navigation.bind(this);
	}	
	navigation(event) {	//function interprets by id how user want to navigate
		let	targetId = event.target.id,
			year = parseInt(document.getElementById('Year').value),
			month = parseInt(document.getElementById('Month').value);
		if (targetId === "previous" )
		{
			year = month === 0 ? year - 1 : year;
			month = (month === 0) ? 11 : month - 1;
			this.props.calendarSkipTo(month, year);
		}
		else if (targetId === "next")
		{
			year = month === 11 ? year + 1 : year;
			month = (month + 1) % 12;
			this.props.calendarSkipTo(month, year);
		}
		else
		{
			this.props.calendarSkipTo(month, year);
		}
	}

	render() {

		const arrowCalendarNavigation = <div className='form-inline'>
		    <button className={"btn btn-outline-dark col-6"} id='previous' onClick={this.navigation}>{this.props.previousHeader}</button>
		    <button className={"btn btn-outline-dark col-6"} id='next' onClick={this.navigation}>{this.props.nextHeader}</button>
			</div>;
		const selectMonth = 
		<select 
			className="form-control col-6"
			value={this.props.currentMonth} 
			name={"Month"} 
			id={"Month"} 
			onChange={this.navigation}>
				{this.props.monthHeaders.map((month, index)=>
					<option 
					value={index} 
					key={index}>
					{month}
					</option>
					)}
			</select>
		const selectYear = 
		<select 
			className="form-control col-6" 
			name={"Year"} 
			id={"Year"} 
			value={this.props.currentYear}
			onChange={this.navigation}>
				{this.props.selectableYears.map((year, index) => 
					<option
						key={index}
						value={year}>
						{year}
				</option>)}
			</select>
		const selectCalendarModule = 
		<div className="form-inline">
			{selectMonth}{selectYear}
		</div>;
	return (
		<div>
			{selectCalendarModule}
			<br/>
			{arrowCalendarNavigation}
		</div>			
		);
	}
}

NavigationCalendar.propTypes ={
	currentMonth: PropTypes.number.isRequired,
	currentYear: PropTypes.number.isRequired,
	selectableYears: PropTypes.arrayOf(PropTypes.number).isRequired,
	monthsHeaders: PropTypes.arrayOf(PropTypes.string),
	calendarSkipTo: PropTypes.func.isRequired
}

export default NavigationCalendar;

