import { elsa } from "./ElsaParticlesContent";

const sceneplay = function (scene, lang){
   const scenes = {
        hello: ['hei_im_elsa', 'show_me_what_you_are_interest_in'],
        flyers: [],
        boring: ['how_can_we_help_you', 'look_around_for_possibilities', 'id_love_to_tell_you_about_offer'],
        aboutus: ['about_lc', 'about_lc_minion_1', 'about_lc_minion_2', 'about_lc_endline'],
        kontakt: ['kontakt', 'look_around_for_possibilities'],
        prices: [],
        tilbud: ['askforprice'],
        offer: ['id_love_to_tell_you_about_offer', 'look_around_for_possibilities','how_can_we_help_you','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        code: ['code_main', 'code_integration'],
        packages: ['package_logocard', 'package_logocardcar', 'package_websiteads', 'package_socialads', 'package_socialadsfoto'],
        website: ['website', 'website_quick', 'website_quality', 'website_copywright', 'website_modules', 'module_bookingcalendar', 'module_virtualassistant', 'module_contactform', 'website_price', 'website_annualfee', 'website_adminpanel', 'website_example', 'how_can_we_help_you', 'website_email'],
        modules: ['module_bookingcalendar', 'module_virtualassistant', 'module_contactform'], //wirtualna asystenkabooking calendar, zaawansowane formularze
        app: ['app', 'app_adminpanel', 'app_price', 'app_annualfee'],
        estore: ['estore', 'estore_input', 'estore_adminpanel', 'estore_price', 'estore_annualfee'],
        graphicdesign: ['graphicdesign_main', 'logo', 'cards', 'carads', 'layouts', 'graphicservices', 'graphicservice_edit', 'graphicservice_labels', 'graphicservice_adsproducts'],
        logo: ['logo_example'],
        businesscard: [],
        foto: ['fotovideo_main', 'foto', 'fotosessions', 'fotosession_product', 'fotosession_portrait', 'fotosession_reports', 'fotosession_architecture', 'fotosession_interior', 'fotosession_family', 'fotosession_event', 'fotosession_drone', 'fotosession_quick', 'videosession', 'video_ads'],
        marketing: ['marketing_main', 'googleads', 'facebookads', 'socialmedia', 'googlemybusiness', 'finn', 'mittanbud', 'marketingbranding', 'marketing_seo'],
        supportit: ['supportit_main', 'supportit_business', 'supportit_remote', 'supportit_training','supportit_fix' ],
        search: [],
        test: ['test'],
    }
    /* 
    const scenes = {
        hello: ['hei_im_elsa', 'show_me_what_you_are_interest_in'],
        flyers: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        boring: ['how_can_we_help_you', 'look_around_for_possibilities', 'id_love_to_tell_you_about_offer'],
        aboutus: ['about_lc', 'about_lc_minion_1', 'about_lc_minion_2', 'about_lc_endline'],
        kontakt: ['kontakt','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        prices: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        tilbud: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        packageprice: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        offer: ['id_love_to_tell_you_about_offer', 'look_around_for_possibilities','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        code: ['code_main', 'code_integration', 'test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        packages: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        website: ['website', 'test','test','test','website_modules','module_bookingcalendar','test','test','test','website_annualfee','test','test','test','test','test','test'],
        modules: ['module_bookingcalendar', 'test', 'test'], //wirtualna asystenkabooking calendar, zaawansowane formularze
        app: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        estore: ['estore', 'test', 'test', 'test', 'estore_annualfee'],
        graphicdesign: ['graphicdesign_main', 'logo', 'cards', 'carads', 'layouts','test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        logo: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        businesscard: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        foto: ['fotovideo_main', 'foto', 'fotosessions', 'fotosession_product', 'fotosession_portrait', 'fotosession_reports', 'fotosession_architecture', 'fotosession_interior', 'fotosession_family', 'fotosession_event', 'fotosession_drone', 'fotosession_quick', 'videosession', 'video_ads'],
        marketing: ['marketing_main', 'googleads', 'facebookads', 'socialmedia', 'googlemybusiness', 'test', 'test', 'marketingbranding', 'marketing_seo','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        supportit: ['supportit_main','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        search: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        test: ['test'],
    }*/

    const scenario = scenes[scene];
    const requestedSceneplay = scenario.map((scene)=>{
        return  elsa(scene, lang);
    });
    return requestedSceneplay;
}

export {sceneplay};