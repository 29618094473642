const langObject = {
    description: {
        pl: {
            code: [
                'strony internetowe',
                'wirtualny asystent',
                'Live Chat',
                'Systemy rezerwacji online',
                'galeria zdjęć',
                'formularze',
                'Integracja aplikacji'
            ],
            marketing: [
                'pozycjonowanie',
                'google Ads',
            ],
            graphic: [
                'Logo', 
                'Wizytówki',
                ],
            foto: [
                'Fotografia i rejestracja wideo',
                'Ujęcia dronem',
                'Spoty reklamowe'       
            ],
        },
        en: {
            code: [
                'Webdesign',
                'Virtual Web Assistants',
                'Live Chat',
                'Online booking systems',
                'Photo galleries',
                'Forms',
                'Application integration'
            ],
            marketing: [
                'SEO',
                'google Ads',
            ],
            graphic: [
                'Logo',
                'Business cards',
                ],
            foto: [
                'Photography and video recording',
                'Drone photography',
                'Advertising films'
            ],
        },  
        no: {
            code: [
                'Webdesign',
                'Virtuelle assistenter',
                'Live Chat',
                'online bookingsystemer',
                'Fotogallerier',
                'Skjemaer',
                'Applikasjonsintegrasjon'
            ],
            marketing: [
                'SEO',
                'google Ads',
            ],
            graphic: [
                'Logo',
                'Visittkort',
                ],
            foto: [
                'Fotografi og videoopptak',
                'Dronefotografering',
                'Reklamefilmer'
            ],
        },      
    },
    content: {
        pl: {
            code: [
                    //Webdesign
                    [
                        'Stworzymy Twoją profesjonalną i nowoczesną stronę internetową, za pomocą której z łatwością będziesz komunikować się ze swoimi klientami.',
                        'Zapewniamy kompleksową obsługę w zakresie tworzenia aplikacji i innych nowoczesnych rozwiązań e-biznesu.',
                        'Posiadamy kwalifikacje umożliwiające nam tworzenie oprogramowania z pełną odpowiedzialnością za kontrolę i jakość na każdym z etapów życia oprogramowania.',
                        'Zapewniamy bezpieczną i niezawodną infrastrukturę serwerową realizującą funkcjonowanie serwisu i poczty e-mail klienta. ',
                    ],
                    //Virtuelle assistent
                    [
                        'Wirtualny asystent pozwala w innowacyjny sposób opowiedzieć o usługach i ofercie.',
                        {
                            module: 'list',
                            list: [
                                'Pokieruje klienta przez stresujący proces reklamacji',
                                'zautomatyzuje pierwszą linii wsparcia obsługi klienta',
                                'poprowadzi wstępny wywiad z klientem',
                                'oraz udzielić odpowiedzi na powszechne w Twojej branży pytania.'
                            ]
                        },
                        'Moduł wirtualnego asystenta można połączyć z modułem Live Chat tak by klient w razie potrzeby mógł znaleźć pomoc u asystenta po drugiej stronie ekranu.'
                    ],
                    //Live Chat
                    [
                        'Funkcja komunikatora umożliwia bezpośredni kontakt z obsługą klienta z poziomu strony internetowej. W formie pisemnej klient może sformułować pytanie i niemal natychmiast otrzymać odpowiedź zwrotną. '
                    ],
                    //Online Bookingsystemer
                    [
                    'Kluczowa funkcjonalność stron hoteli, usług kosmetycznych i medycznych',
                    {
                        module: 'pagebreak',
                        button: ['Zobacz demo', 'Powrót'],
                        content: [
                            {module: 'booking'},
                        ]
                    
                    }
                    ],
                    //fotogallerier
                    [
                    'Fotogaleria online na Twojej stronie internetowej najlepiej wyeksponuje reprezentacyjne projekty.',
                    'Właściwie wkomponowana galeria zdjęć online wzmocni skuteczność Twojej reklamy na stronie internetowej. Dzięki fotogalerii potencjalni klienci upewnią się co do jakości Twoich prac i chętniej skontaktują się z Tobą.',
                    'Fotografie zrealizowanych prac służą jako dobry punkt odniesienia przy rozmowie o nowym projekcie z niezdecydowanym klientem. ',
                    {
                        module: 'pagebreak',
                        button: ['Zobacz demo', 'Powrót'],
                        content: [
                            'Galeria przed - po',
                            ['2_before.jpg', '2_after.jpg'],
                        ]
                    
                    }

                    ],
                    //skjemaer
                    [
                        'Strona internetowa pozwala na nieograniczone formy interakcji. Poza wizytówką Twojej firmy na stronie możemy umieścić formularz i przeprowadzić wstępny wywiad z klientem.',
                        'Za pomocą formularza możesz: ',
                        {
                            module: 'list',
                            list: [
                                'określić zakres prac,',
                                'zadać standardowe pytania,',
                                'pobrać niezbędne w Twojej pracy wymiary,',
                                'obliczyć wycenę, którą możesz przesłać dalej w formie dokumentu pdf.' 
                            ]
                        }
                    ],
                    //app integration
                    [
                        'Zintegrowanie aplikacji zapewnia wymianę informacji między różnymi aplikacjami.',
                        'Celem jest stworzenie zunifikowanego systemu do obsługi organizacji, który zajmie się wszystkim: od obsługi klienta, po obsługę księgową, finansową i magazynową firmy.'
                    ],
                ],
            marketing: [
                    [
                        'Zoptymalizujemy i wypozycjonujemy Twoją stronę, dzięki czemu Twoja strona będzie widoczna po najpopularniejszych frazach kluczowych w Twojej branży.',
                        'Główne cele optymalizacji SEO:',
                        {module: 'list',
                        list: ['optymalizacja Meta Tagów i znaczników Title',
                        'optymalizacja nagłówków',
                        'poprawienie opisów grafik zawartych na stronie',
                        'usunięcie błędów strony',
                        'skrócenie czasu ładowania strony',
                        'optymalizacja struktury podstron',
                        'wzbogacenie witryny o unikalną treść',]},
                        'Proces optymalizacji jest procesem złożonym z etapów wprowadzanych systematycznie. Wstępna optymalizacja uzyskiwana jest po około miesiącu prac.',
                        {module: 'pagebreak',
                        button: [
                            'zobacz przykład', 'powrót',
                        ],
                        content: [
                            'Ostgulvsliping AS jest firmą oferującą swoje usługi na terenie Oslo i okolic.',
                            'Głównym celem kampanii SEO było osiągnięcie czołowych pozycji dla fraz: gulvbehandling, gulvsliping, parkettsliping dla lokalizacji Oslo.',
                            'Pierwszym etapem kampanii SEO było przeprowadzenie audutu SEO na podstawie którego wykonaliśmy wstępną optymalizację.',
                            'Przez kolejne miesiące urozmaicaliśmy stronę o niepowtarzalne treści w postaci artykułów i kontynuowaliśmy optymalizację by w efekcie osiągnąć w efekcie  zadowalające efekty w TOP 10 dla fraz gulvbehandling, gulvsliping, parkettsliping przy lokalizacji Oslo.',
                            ['gulvbehandling_oslo_before.png', 'gulvbehandling_oslo_after.png'],
                            ['gulvsliping_oslo_before.png', 'gulvsliping_oslo_after.png'],
                        ],
                        },
                    ],
                    [
                        'Przeprowadzimy Google Ads w wynikach wyszukiwania, kampanie Google Shopping, kampanie w sieci reklamowej, Remarketing, reklamy na You Tube.',
                    ],
                ],
            graphic: [
                [
                    'Logo otaczają nas ze wszystkich stron i stanowią nieodłączny element naszego życia. Ludzie są wzorokowcami, to co widzą klienci jest ważną częscią firmy.',
                    {module: 'portfolio'}
                ],
                [
                    'Istotne jest, aby wizytówki były odpowiednio zaprojektowane i wydrukowane na wysokiej jakości papierze.',
                    {video: 'ad2'}
                ],
                
            ],
            foto: [
                    [
                        'Specjalizujemy się w:',
                        {
                            module: 'list',
                            list: [
                                '- zdjęciach architektury', 
                                '- wnętrz', '- reportażowych',
                                '- oraz na potrzeby e-commerce.'
                            ]
                        }
                    ],
                    [
                        'Zdjęcia promocyjne z drona dla firm, agentów nieruchomości oraz deweloperów to nasza specjalność.',
                        'Inspekcja dachu dronem i innych trudno dostępnych miejsc to świetne narzędzie, które pomaga w odbiorze nieruchomości.',
                        {video: 'ad1'}
                    ],
                    [
                        'Wideo marketing zapewnia rozpoznawalność i niejednokrotnie przewagę konkurencyjną. ',
                        'Dobrze zrealizowany film wizerunkowy, ułatwi klientom lepsze zrozumienie marki, a firmie zwiększy poziom sprzedaży. ',
                        'Film umożliwia doskonałe wyeksponowanie najważniejszych zalet.',
                    ]       
                ],
            },
            en: {
                code: [
                        //Webdesign
                        [
                            'We can create your modern, professional website through which you can easily communicate with your clients.',
                            'We provide comprehensive services in the field of application development and other modern e-business solutions.',
                            'We are qualified to create software with full responsibility for control and quality at every stage of the software\'s life.',
                            'We provide a safe and reliable server infrastructure supporting the client\'s website and e-mail.',

                        ],
                        //Virtuelle assistent
                        [
                            'A virtual assistant allows you to talk about services and offers in an innovative way.',
                            {
                                module: 'list',
                                list: [
                                    'guides the customer through the complaint process',
                                    'automates first line customer service support',
                                    'conducts an initial interview with the client',
                                    'and answer common questions in your industry.'
                                ]
                            },
                            'The virtual assistant module can be connected to the Live Chat module so that, if necessary, the customer can find help from the assistant on the other side of the screen.'
                        ],
                        //Live Chat
                        [
                            'The messenger function allows direct contact with customer service from the website. In writing, the customer can formulate a question and receive a response almost immediately. '
                        ],
                        //Online Bookingsystemer
                        [
                            'Key functionality of hotel, beauty and medical services websites',
                            {
                                module: 'pagebreak',
                                button: ['demo', 'Back'],
                                content: [
                                    {module: 'booking'},
                                ]
                            
                            }
                        ],
                        //fotogallerier
                        [
                            'An online photo gallery on your website can best display representative projects.',
                            'A properly composed online photo gallery will enhance the effectiveness of your website. Thanks to the photo gallery, potential customers will be sure of the quality of your work and will be more willing to contact you.',
                            'Photos of completed works are a good reference point when talking about a new project with an undecided client.',
                            {
                                module: 'pagebreak',
                                button: ['See demo', 'Back'],
                                content: [
                                    'Gallery before - after',
                                    ['2_before.jpg', '2_after.jpg'],
                                ]
                           
                            }
                            ],
                    //skjemaer
                    [
                        'The website allows for unlimited forms of interaction. In addition to your company\'s business card, we can place a form on your website and conduct an initial consultation with the client.',
                        'Using the form you can: ',
                        {
                            module: 'list',
                            list: [
                                'define the scope of work,',
                                'ask standard questions,',
                                'take the dimensions necessary for your work,',
                                'calculate an offer that you can forward as a pdf document.'                      ]
                        }
                    ],
                    //app integration
                    [
                        'Integration of applications ensures the exchange of information between different applications.',
                        'The goal is to create a unified system for managing the organization, which will take care of everything: from customer service to accounting, financial and warehouse management of the company.'
                    ],
                ],
            marketing: [
                    [
                        'We can optimize and position your website in search engines, thanks to which your website will be visible in the most popular key phrases for your industry.',
                        'Main SEO goals:',
                        {module: 'list',
                            list: [
                            'optimization of Meta Tags and Title tags',
                            'header optimization',
                            'improving descriptions of graphics contained on the website',
                            'removing page errors',
                            'reducing page loading time',
                            'subpage structure optimization',
                            'enriching the website with unique content',
                            ]
                        },
                        'The optimization process is a process composed of stages introduced systematically. Initial optimization is achieved after about a month of work.',
                        {module: 'pagebreak',
                        button: [
                            'zobacz przykład', 'powrót',
                        ],
                        content: [
                            'Ostgulvsliping AS is a company offering floor sanding services in Oslo and the surrounding area.',
                            'The main goal of the SEO campaign was to achieve top positions for the phrases: gulvbehandling, gulvsliping, parkettsliping for the Oslo location.',
                            'The first stage of the SEO campaign was to conduct an SEO audit, based on which we performed initial optimization.',
                            'Over the following months, we diversified the website with unique content in the form of articles and continued optimization to achieve satisfactory results in the TOP 10 for the phrases gulvbehandling, gulvsliping, parkettsliping in the Oslo location.',
                            ['gulvbehandling_oslo_before.png', 'gulvbehandling_oslo_after.png'],
                            ['gulvsliping_oslo_before.png', 'gulvsliping_oslo_after.png'],
                        ],
                        },
                    ],
                    [
                        'We can carry out Google Ads in search results, Google Shopping campaigns, advertising network campaigns, Remarketing, You Tube advertising for your company.',
                    ],
                ],
                graphic: [
                    [
                        'Logos are an integral part of our lives. What customers see is an important part of the company.',
                        {module: 'portfolio'}
                    ],
                    [
                        'It is important that business cards are properly designed and printed on high-quality paper.',
                        {video: 'ad2'}
                    ],
                    
                ],
                foto: [
                        [
                            'We specialize in:',
                            {
                                module: 'list',
                                list: [
                                    '- architecture photos',
                                    '- interiors', '- reportage',
                                    '- and for e-commerce purposes.'
                                ]
                            }
                        ],
                        [
                            'We take promotional shots from a drone for companies, real estate agents and developers.',
                            'Drone inspection of the roof and other hard-to-reach places is a great tool that helps in the inspection of the property.',
                            {video: 'ad1'}
                        ],
                        [
                            'Video marketing provides recognition and a competitive advantage. ',
                            'A well-made image film will help customers better understand the brand, and it can increase the company\'s sales level. ',
                            'The film allows for perfect exposure of the most important advantages.',
                        ]       
                    ],
                },
                no: {
                    code: [
                            //Webdesign
                            [
                                'Vi kan lage din moderne, profesjonelle nettside der du enkelt kan kommunisere med kundene dine.',
                                'Vi tilbyr omfattende tjenester innen applikasjonsutvikling og andre moderne e-business-løsninger.',
                                'Vi er kvalifisert til å lage programvare med fullt ansvar for kontroll og kvalitet i alle stadier av programvarens liv.',
                                'Vi tilbyr en sikker og pålitelig serverinfrastruktur som støtter klientens nettsted og e-post.',  
                            ],
                            //Virtuelle assistent
                            [
                                'En virtuell assistent lar deg snakke om tjenester og tilbud på en innovativ måte.',
                                {
                                    module: 'list',
                                    list: [
                                        "veileder kunden gjennom klageprosessen",
                                        'automatiserer førstelinje kundeservice',
                                        "gjennomfører et innledende intervju med klienten",
                                        "og svar på vanlige spørsmål i din bransje."
                                    ]
                                },
                                'Den virtuelle assistentmodulen kan kobles til Live Chat-modulen slik at kunden om nødvendig kan finne hjelp fra assistenten på den andre siden av skjermen.'
                            ],
                            //Live Chat
                            [
                                'Messenger-funksjonen tillater direkte kontakt med kundeservice fra nettstedet. Skriftlig kan kunden formulere et spørsmål og få svar nesten umiddelbart. '
                            ],
                            //Online Bookingsystemer
                            [
                                'Nøkkelfunksjonalitet til nettsteder for hotell, skjønnhet og medisinske tjenester',
                                {
                                    module: 'pagebreak',
                                    button: ['demo', 'Tilbake'],
                                    content: [
                                        {module: 'booking'},
                                    ]
                                
                                }
                            ],
                            //fotogallerier
                            [
                                'Et fotogalleri på nettstedet ditt kan best vise representative prosjekter.',
                                'Et riktig sammensatt nettbildegalleri vil forbedre effektiviteten til nettstedet ditt. potensielle kunder vil være sikre på kvaliteten på arbeidet ditt takket være fotogalleriet. De vil helt sikkert være villige til å kontakte deg.',
                                'Bilder av fullførte arbeider er et godt referansepunkt når du snakker om et nytt prosjekt med en usikre klient.',
                                {
                                    module: 'pagebreak',
                                    button: ['demo', 'Tilbake'],
                                    content: [
                                        'Gallery before - after',
                                        ['2_before.jpg', '2_after.jpg'],
                                    ]
                               
                                }
                                ],
                        //skjemaer
                        [
                            'Nettstedet gir mulighet for ubegrensede former for interaksjon. I tillegg til bedriftens visittkort, kan vi legge inn et skjema på nettstedet ditt og gjennomføre en innledende konsultasjon med kunden.',
                            'Ved å bruke skjemaet kan du:',
                            {
                                module: 'list',
                                list: [
                                    'definer omfanget av arbeidet',
                                    'stille standard spørsmål',
                                    'ta dimensjonene som er nødvendige for arbeidet ditt',
                                    'beregn et tilbud som du kan videresende som et pdf-dokument.'
                                ]
                            }
                        ],
                        //app integration
                        [
                            'Integrasjon av applikasjoner sikrer utveksling av informasjon mellom ulike applikasjoner.',
                            "Målet er å skape et enhetlig system for å administrere organisasjonen, som vil ta seg av alt: fra kundeservice til regnskap, økonomi og lagerstyring av selskapet."
                        ],
                    ],
                marketing: [
                        [
                            'Vi kan optimere og posisjonere nettstedet ditt i søkemotorer, takket være at nettstedet ditt vil være synlig i de mest populære nøkkelfrasene for din bransje.',
                            'Hoved SEO-mål:',
                            {module: 'list',
                                list: [
                                'optimization of Meta Tags and Title tags',
                                'optimalisering av  Meta Tags og Title tags',
                                'overskriftsoptimalisering',
                                "forbedre beskrivelser av grafikk på nettstedet",
                                'fjerner sidefeil',
                                'redusere sideinnlastingstid',
                                'optimalisering av undersidestruktur',
                                "berike nettstedet med unikt innhold",
                                ]
                            },
                            'Optimaliseringsprosessen er en prosess som består av stadier som introduseres systematisk. Innledende optimalisering oppnås etter omtrent en måneds arbeid.',
                            {module: 'pagebreak',
                            button: [
                                'se eksempel', 'tilbake',
                            ],
                            content: [
                                'Ostgulvsliping AS er et selskap som tilbyr gulvslipingtjenester i Oslo og omegn.',
                                'Hovedmålet med SEO-kampanjen var å oppnå topplasseringer for setningene: gulvbehandling, gulvsliping, parkettsliping for Oslo-lokasjonen.',
                                'Den første fasen av SEO-kampanjen var å gjennomføre en SEO-revisjon, basert på den innledende optimaliseringen.',
                                'I løpet av de påfølgende månedene har vi diversifisert nettstedet med unikt innhold i form av artikler og fortsatt optimalisering for å oppnå tilfredsstillende resultater på TOP 10 for setningene gulvbehandling, gulvsliping, parkettsliping i Oslo-lokasjonen.',
                                ['gulvbehandling_oslo_before.png', 'gulvbehandling_oslo_after.png'],
                                ['gulvsliping_oslo_before.png', 'gulvsliping_oslo_after.png'],
                            ],
                            },
                        ],
                        [
                            'Vi kan utføre Google Ads i søkeresultater, Google Shopping-kampanjer, kampanjer i annonsenettverk, remarketing, YouTube-annonsering for bedrifter.'
                        ],
                    ],
                    graphic: [
                        [
                            'Logoer og er en integrert del av livene våre. Det kundene ser er en viktig del av selskapet.',
                            {module: 'portfolio'}
                        ],
                        [
                            'Det er viktig at visittkort er riktig utformet og trykket på papir av høy kvalitet.',
                            {video: 'ad2'}
                        ],
                        
                    ],
                    foto: [
                            [
                                'Vi er spesialist på:',
                                {
                                    module: 'list',
                                    list: [
                                        '- arkitekturbilder',
                                        '- interiør', '- reportasje',
                                        '- og for e-handelsformål.'
                                    ]
                                }
                            ],
                            [
                                'Vi tar reklamebilder fra en drone for selskaper, eiendomsmeglere og utviklere.',
                                 'Droneinspeksjon av taket og andre vanskelig tilgjengelige steder er et flott verktøy som hjelper til med inspeksjonen av eiendommen.',
                                {video: 'ad1'}
                            ],
                            [
                                'Videomarkedsføring gir anerkjennelse og et konkurransefortrinn. ',
                                'En godt laget bildefilm vil hjelpe kundene bedre å forstå merkevaren, og den kan øke selskapets salgsnivå. ',
                                'Filmen tillater perfekt eksponering av de viktigste fordelene.',

                            ]       
                        ],
                    },
    },
    caption: {
        pl: {
             code: [
                    'Stworzymy Twoją profesjonalną i nowoczesną stronę internetową, dzięki której będziesz mógł komunikować się ze swoimi klientami.',
                    '',
                    '',
                    'Kluczowa funkcjonalsność stron hoteli, usług kosmetycznych i medycznych', 
                    '', 
                    '',                                      
                    'Tak jak budowlańcy łączą różne materiały w niezwykłe budowle my potrafimy połączyć różne rozwiązania informatyczne w użyteczną całość.'
                ],
            marketing: [
                'SEO',
                'google Ads',
            ],                
            graphic: [
                'Logo to dla klienta pierwsze wrażenie Twojej firmy',
                'To bardzo silne narzędzie reklamowe',
                ],
            foto: [
                    'Wykonamy profesjonalne zdjęcia reklamowe.',
                    'Stworzymy piękne ujęcia z lotu ptaka w najlepszej jakości 4K',
                    'Video, to najskuteczniejsza forma reklamy!'       
                ],
            },
        en: {
            code: [
                    'Stworzymy Twoją profesjonalną i nowoczesną stronę internetową, dzięki której będziesz mógł komunikować się ze swoimi klientami.',
                    '',
                    '',
                    '',
                    '',
                    '',                                       
                    'Tak jak budowlańcy łączą różne materiały w niezwykłe budowle my potrafimy połączyć różne rozwiązania informatyczne w użyteczną całość.'
                ],
            marketing: [
                'SEO',
                'google Ads',
            ],
            graphic: [
                'Logo to dla klienta pierwsze wrażenie Twojej firmy',
                'To bardzo silne narzędzie reklamowe',
                ],
            foto: [
                    'Wykonamy profesjonalne zdjęcia reklamowe.',
                    'Stworzymy piękne ujęcia z lotu ptaka w najlepszej jakości 4K',
                    'Video, to najskuteczniejsza forma reklamy!'       
                ],
            },
        no: {
             code: [
                    'Vi kan lage din profesjonelle og moderne nettside, takket være den vil du kunne kommunisere med kundene dine.',
                    '',
                    '',
                    'Nøkkelfunksjonalitet til hotellnettsteder, kosmetikk og medisinske tjenester',
                    '',
                    '',                                       
                    'Applikasjonsintegrasjon sikrer utveksling av informasjon mellom ulike applikasjoner. Målet er å lage et enhetlig system for å støtte organisasjonen som skal ta seg av alt fra kundeservice til regnskap, økonomi og lagertjenester for selskapet.'
                ],
                marketing: [
                    'Vi kan optimalisere og posisjonere nettstedet ditt, nettstedet ditt vil være synlig med de mest populære nøkkelsetningene i din bransje.', 
                    'Vi kan utføre Google Ads i søkeresultater, Google Shopping-kampanjer, kampanjer i annonsenettverk, remarketing, annonser på YouTube.',
                    'Vi kan bygge et profesjonelt image av bedriften din og hjelpe deg med å skape en gjenkjennelig merkevare som vil få anbefalinger.',
                ],
            graphic: [
                'Logoer omgir oss og er en integrert del av livene våre. Det kundene ser er en viktig del av selskapets image.',
                'Det er viktig at visittkort er riktig utformet og trykt på papir av høy kvalitet.',
            ],
            foto: [
                    'Vi kan ta profesjonelle reklamebilder',
                    'Vi kan lage vakre luftbilder i den beste 4K-kvaliteten',
                    'Video er den mest effektive formen for annonsering!'       
                ],
            },
    },
    title: {
        pl: ['Webdesign', 'Online Marketing', 'Projektowanie graficzne' , 'Foto & Wideo'],
        en: ['Webdesign', 'Online Marketing',  'Graphic design', 'Photo & Video'],
        no: ['Webdesign', 'Online Marketing', 'Grafisk design', 'Foto & Video'],
    },
    about: {
        pl: [
            'Agencja Kreatywna A-Z',
            'Projektujemy strony i sklepy internetowe, aplikacje mobile, systemy IT. Zajmujemy się wizerunkiem i promocją marki. Prowadzimy kampanie reklamowe w Internecie i mediach. Wykonujemy projekty graficzne: logo, wizytówki, etykiety, reklamy na samochodach. Usługi fotograficzne, zdjęcia dronem, reklamy wideo. Wsparcie IT i szkolenia.',
            'Każdy projekt jakiego się podejmiemy realizujemy z pasją dając z siebie 110%. Lubimy wyzwania i angażujemy się w powierzone zadanie. Wyznajemy zasadę, że zadowolony klient to nasza najlepsza wizytówka.'
        ],
        en: [
            'Creative Agency A-Z',
            'We design websites and online stores, mobile applications, IT systems. We deal with the image and promotion of the brand. We run advertising campaigns on the Internet and in the media. We make graphic designs: logos, business cards, labels, advertising on cars. Photography services, drone photos, video ads. IT support and training. ',
            'Every project we undertake is carried out with passion, giving 110% of ourselves. We like challenges and we engage in the entrusted task. We adhere to the principle that a satisfied customer is our best business card. '    
        ],
        no: [
            'Creative Agency A-Å',
              'Vi designer nettsider og nettbutikker, mobilapplikasjoner, IT-systemer. Vi tar for oss bildet og promoteringen av merkevaren. Vi kjører annonsekampanjer på internett og i media. Vi lager grafisk design: logoer, visittkort, etiketter, reklame på biler. Fototjenester, dronebilder, videoannonser. IT-støtte og opplæring.',
              'Hvert prosjekt vi gjennomfører utføres med lidenskap, og gir 110 % av oss selv. Vi liker utfordringer og engasjerer oss i den betrodde oppgaven. Vi følger prinsippet om at en fornøyd kunde er vårt beste visittkort.'
        ],
    },
    cookies: {
        pl: 'Na stronie Laskowski Creative my i nasi partnerzy używamy plików cookie do dostosowywania reklam i pomiarów. Możesz przeczytać więcej na ten temat w naszej polityce prywatności i naszej stronie z informacjami o plikach cookie.',
        en: 'On Laskowski Creative websites, we and our partners use cookies for personalization of ads and measurement. You can read more about this in our privacy policy and our cookie information page.',
        no: 'På Laskowski Creative nettsider bruker vi og våre partnere informasjonskapsler (cookies) for personlig tilpasning av annonser og måling. Du kan lese mer om dette i vår personvernpolicy og vår informasjonsside om informasjonskapsler.',
    },
    navigation: {
        pl: ['portfolio', 'kontakt'],
        en: ['portfolio', 'contact us'],
        no: ['portfolio', 'kontakt oss']
    },
    kontakt: {
        pl: ['imię', 'e-mail / tel', 'nr telefonu', 'treść'],
        en: ['name', 'e-mail / phone',   'phone', 'message'],
        no: ['navn', 'e-post / tlf', 'tlf', 'melding']
    },
    kontaktRules: {
        pl: 'Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w niniejszym formularzu zapytania przez Laskowski Creative w celu skontaktowania się z Państwem i udzielenia odpowiedzi na zadane pytania.',
        en: 'I accept the processing of my personal information given in this contact form by Laskowski Creative to contact you and answer the question asked.',
        no: 'Jeg godtar behandlingen av mine personlige opplysninger gitt i dette forespørselsskjemaet av Laskowski Creative for å kontakte deg og svare på det stilte spørsmålet.'
    },
    accept: {
        pl: 'Rozumiem',
        en: 'I understand',
        no: 'Jeg forstår'
    }
    

}
  const chooseLanguage = (lang) =>
{
	let outputObject = {};
	for (let [collectionName, languagesCollections] of Object.entries(langObject)) {
		outputObject[collectionName] = languagesCollections[lang];
	}
	return outputObject;
}
export {chooseLanguage};