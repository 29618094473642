import React, { Component } from 'react';
import './popup.css'
import { getClientData } from './DataModelEngine';

class MenuPopup extends Component {
    constructor () {
        super();
          this.state = {
            menuExtended: false,
          }
        }
    onClick = () => {
        let {menuExtended} = this.state;
        menuExtended = !menuExtended;
        this.setState({
            menuExtended
        })
    }
    selectMenuItem = (selected) => {
        let {showFullPage} = this.props;
        showFullPage(selected)
    }

	render() {
        const {menuExtended} = this.state;
        const {siteContent} = this.props;        

		return (
			<div id='menu-popup' onClick={this.onClick}>
                {<div id='menu-popup-hamburger' onClick={this.onClick}>
                    <div id='menu-popup-hamburger-beam'></div>
                    <div id='menu-popup-hamburger-beam'></div>
                    <div id='menu-popup-hamburger-beam'></div>
                </div>}
                {menuExtended && <>
                    <div id='menu-popup-ico' onClick={()=>{this.selectMenuItem(false)}}>
                       <div id='menu-ico-home' className='menu-ico'></div>
                       <div id='menu-caption'>Home</div>
                    </div>
                    {
                        siteContent.map((site, index)=>(
                        <div id='menu-popup-ico' onClick={()=>{this.selectMenuItem(site.link)}}>
                            <div id={'menu-ico-'+index} className='menu-ico'></div>
                            <div id='menu-caption'>{site.title}</div>
                        </div>                           
                        ))
                    } 
                    <div className='popup-backgroud'></div>             
                </>}
			</div>
		) 
	}
}

export default MenuPopup;