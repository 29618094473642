import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {defaultValues} from './../booking-calendar-utility/defaultValues';


class DayCalendar extends Component {
	constructor (props) {
		super(props);
		this.selectDay = this.selectDay.bind(this);
		this.showDetails = this.showDetails.bind(this);
	}
	selectDay(event){
		const today = new Date();
		const name = event.target.name;
		const date = event.target.id;
		if(name==="free")
		{
			const inputDate = {
					day: parseInt(date.slice(0, date.indexOf('/'))),
					month: parseInt(date.slice(date.indexOf('/')+1, date.lastIndexOf('/'))),
					year: parseInt(date.slice(date.lastIndexOf('/')+1)),
			}
				let newBooking;
					//New booking can't happen before today
				if(inputDate.year > today.getFullYear() 
				|| inputDate.month > today.getMonth() 
				|| (inputDate.year === today.getFullYear()
					&& inputDate.month === today.getMonth() 
					&& inputDate.day >= today.getDate())
				)
				{//operate on user input
					if(this.props.newBooking.selectedDayCount === 0)
					{//when it's first input set as checkin
						newBooking = {
							...this.props.newBooking,
							object: {
								...defaultValues.newBookingObject,
								checkin: {...inputDate}
							},
							selectedDayCount: 1
						};
					}
					else if (this.props.newBooking.selectedDayCount === 1)
					{
						//console.log(this.props.newBooking[0]);
						if(inputDate.year > this.props.newBooking.object.checkin.year
						|| inputDate.month > this.props.newBooking.object.checkin.month 
						|| (inputDate.year === this.props.newBooking.object.checkin.year 
							&& inputDate.month === this.props.newBooking.object.checkin.month
							&& inputDate.day >= this.props.newBooking.object.checkin.day ) )
						{
							let bookingYears = inputDate.year - this.props.newBooking.object.checkin.year;
							let bookingMonths = inputDate.month - this.props.newBooking.object.checkin.month;
							let count = bookingYears*12 + bookingMonths;
							let bookingArrayToCompare = [];
							for (let i=0; i<=count; i++)
							{
								let year = this.props.newBooking.object.checkin.year;
								let month = this.props.newBooking.object.checkin.month;
								if(month+i > 11)
								{
									month = month + i -11;
									year++;
								}
								else
								{
									month = month + i;
								}
								bookingArrayToCompare = bookingArrayToCompare.concat(this.props.createBookingList(this.props.roomId, month, year, defaultValues.calendarCollections.newBookingMode) );
							}

							let conflictValues = bookingArrayToCompare.filter((potencialConflictBooking)=>{
									return potencialConflictBooking.checkout.getTime() <= new Date(inputDate.year, inputDate.month, inputDate.day).getTime()
								&& potencialConflictBooking.checkin.getTime() >= new Date(this.props.newBooking.object.checkin.year, this.props.newBooking.object.checkin.month, this.props.newBooking.object.checkin.day).getTime() 
								})
							if(conflictValues.length === 0){
								newBooking = {
									...this.props.newBooking,
									object: {
										...this.props.newBooking.object,
										checkout: {...inputDate}
									},
									newBookingIsCorrect: true,
									selectedDayCount: 0
								}
							}
							else
							{
								newBooking = {
									object: {...defaultValues.newBookingObject},
									newBookingIsCorrect: false,
									newBookingMode: true,
									selectedDayCount: 0
								};
							}

							//console.log(bookingArrayToCompare);
							//console.log('count: ' + count);
							
							;
						}
						else
						{
							newBooking = {
								object: {...defaultValues.newBookingObject},
								newBookingIsCorrect: false,
								newBookingMode: true,
								selectedDayCount: 0
							};
						}
					}
				}
				else
				{
					newBooking = {
						object: {...defaultValues.newBookingObject},
						newBookingIsCorrect: false,
						newBookingMode: true,
						selectedDayCount: 0
					};
				}
				//get calendar of this specific room and for scope of newbooking months
				//ask checkforconflicts if there's no conflicts 
				//do the rest or not
				console.log(newBooking)
				let array = [{
					bookingId: 'new',
					roomId: this.props.roomId,
					bookingType: 'unconfirmed',
					checkin: newBooking.object.checkin.day !== false ? new Date(newBooking.object.checkin.year, newBooking.object.checkin.month, newBooking.object.checkin.day) : false,
					checkout: newBooking.object.checkout.day !== false ? new Date(newBooking.object.checkout.year, newBooking.object.checkout.month, newBooking.object.checkout.day ) : false,
				}]
				newBooking = {...newBooking, array}
				console.log(newBooking);
				this.props.returnSelectedDay(newBooking);
		}
	}
	showDetails(event) {
		const id = event.target.id;
		if (id.indexOf('/') < 0)
			this.props.changeShowBookingDetailsValue(id);
		else
			this.props.changeShowBookingDetailsValue(false);
	}

	render() {//generate empty cell for days before 1st of the month or cell with button
		const freeDayButtonClass = 'btn btn-light';
		const selectedDayButtonClass = 'btn btn-outline-light';
		//const unselectedDayButtonClass = 'btn btn-outline-light';
		const bookedDayButtonClass = this.props.dayCellColor;
  	const dayButton = <a 
  		//href={this.props.bookingId !== 0 ? '#'+this.props.bookingId : '#free'} 
  		data-toggle='tooltip' 
  		title={this.props.bookingId !== 0 ? 'id: ' + this.props.bookingId + '\n' + this.props.bookingTypeHeaders[this.props.bookingType] : ''}
  	>
			<input 
				type='button' 
				id={this.props.bookingId !== 0 ? this.props.bookingId : this.props.day+'/'+this.props.currentMonth+'/'+this.props.currentYear} 
				value={(this.props.day === 'X' || this.props.day === 'O') ? '' : this.props.day} 
				name={this.props.bookingId !== 0 ? 'details' : 'free'}
				onClick={this.props.newBooking.newBookingMode ? this.selectDay : this.showDetails}
				style= {{width: '100%', position: 'absolute', top:0, right: 0, left: 0, bottom: 0}}
				className={this.props.day === 'X' ? bookedDayButtonClass : (this.props.day ==='O' ? selectedDayButtonClass : freeDayButtonClass) }
			/>
	  </a>;
		const dayCell = <td 
			className='content'
			style={{margin:0, padding: 0}}>
  			{dayButton}
  		</td>;
  	const emptyCell = <td className='content'>{''}</td>;
  	
  	if (this.props.day === 0) 
		{
			return emptyCell;
		}
		else
		{
			return dayCell;
		}
	}
}

DayCalendar.propTypes ={
	day: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]).isRequired,
	bookingId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]),
	bookingType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]),
	returnSelectedDay: PropTypes.func
}

export default DayCalendar;

					