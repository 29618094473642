import React, { Component } from 'react';
import './glitch.css';

class ArticleIco extends Component {
	constructor () {
        super();
        this.state = {

        }

    }



componentDidMount(){
}

    
	render() {
		const siteContent = this.props.siteContent ? this.props.siteContent : [];
        let categories = ['CodePage', 'MarketingPage', 'GraphicDesign', 'FotoPage'];
        let index = categories.indexOf(siteContent.link);
		return (

            <div id={siteContent.link} name={siteContent.link} onClick={()=>{this.selectMenuItem(siteContent.link)}}>		
                       <div id='menu-popup-ico' style={{zIndex: '1'}} onClick={()=>{this.selectMenuItem(siteContent.link)}}>
                            <div id={'menu-ico-'+index} className='menu-ico'></div>
                        </div>   
                  <p name={siteContent.link}>{siteContent.title}</p>
            </div>

		) 
	}
}


export default ArticleIco;