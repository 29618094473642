import React, { Component } from 'react';
import BookingsCalendar from './BookingsCalendar';


class DemoBookingsCalendarContainer extends Component {
	constructor () {
		super();
		this.state = {
		}
		this.generateDemoBookings = this.generateDemoBookings.bind(this);
		this.createId = this.createId.bind(this);
		this.changeBookingCollection = this.changeBookingCollection.bind(this);
		this.addToUnconfirmedCollection = this.addToUnconfirmedCollection.bind(this);
	}
	changeBookingCollection(bookingId, moveFromCollection, destinyCollection)
	{
		//znajduje ale jescze bookingType trzeba zmienić
		let oldCollection = this.state.bookingCollections[moveFromCollection];
		let newCollection = this.state.bookingCollections[destinyCollection];
		let booking = oldCollection.filter((booking) => {return booking.bookingId === bookingId})
		booking[0].bookingType = destinyCollection;
		newCollection = newCollection.concat(booking);
		oldCollection = oldCollection.filter((booking)=>{return booking.bookingId !== bookingId})
		console.log(newCollection);
		console.log(oldCollection);
		this.setState({
			bookingCollections: {
				...this.state.bookingCollections,
				[moveFromCollection] : oldCollection,
				[destinyCollection] : newCollection
			}
		})
		//to destinyCollection concat filtered from moveFromCollection where bookingId
		//to moveFromCollection sfiltruj wszystko poza bookingId
	}
	addToUnconfirmedCollection(bookingObject){
		let checkin = new Date(bookingObject.checkin.year, bookingObject.checkin.month, bookingObject.checkin.day);
		let checkout = new Date(bookingObject.checkout.year, bookingObject.checkout.month, bookingObject.checkout.day);		
		let bookingId = this.createId(checkin, bookingObject.roomId);
		let booking = {
			bookingId,
			bookingType: 'unconfirmed',
			checkin,
			checkout,
			roomId: bookingObject.roomId,
		}
		let unconfirmed = this.state.bookingCollections.unconfirmed;
		console.log(booking);
		console.log(unconfirmed);
		unconfirmed = unconfirmed.concat(booking);
		this.setState({
			bookingCollections: {
				...this.state.bookingCollections,
				unconfirmed
			}
		})
		return bookingId;
	}
	randomizeNumber(maxRandomValue, minRandomValue){
			let randomNumberValue = 0;
			let minimum = minRandomValue ? minRandomValue : 0; 
			while (randomNumberValue <= minimum)
			{
				randomNumberValue = Math.floor(Math.random()*(maxRandomValue+1))
			}
			return randomNumberValue 
		};
	createId(date, roomId){
		const dateObject = {
			day: date.getDate(),
			month: date.getMonth(),
			year: date.getFullYear()
		};
		const rand = String(this.randomizeNumber(5,3));
		const outputId = String(roomId) + dateObject.year+dateObject.month+dateObject.day +rand; 
		return outputId;
	}
	generateDemoBookings(){
		/* create some Demo Rooms, 
		unconfirmed: [], confirmed: [] and canceled: [] bookings */
		const setDates = (checkin, length) => {	
			if (checkin && length)
			{
				let correctCheckinDateValue = new Date (checkin.getFullYear(), checkin.getMonth(), checkin.getDate())
			  let checkout = new Date(checkin.getFullYear(), checkin.getMonth(), checkin.getDate());
			  checkout.setDate(checkout.getDate() + length); 
			  return {checkin: correctCheckinDateValue, checkout};
			}
		}		
		let bookings = [];
		let rooms = [];
		const today = new Date();
		for (let roomId = 1; roomId<this.randomizeNumber(5, 3); roomId++)
		{
			rooms[roomId-1] = roomId;
			let bookingBegining = new Date(today.getFullYear(), today.getMonth(), today.getDate() );
			for (let bookingIdPrefix = 1; bookingIdPrefix<this.randomizeNumber(12, 6); bookingIdPrefix++)
			{
				let bookingFlag = ['confirmed', 'unconfirmed', 'canceled'];
				let length = this.randomizeNumber(7, 3),
						bookingDates = setDates(bookingBegining, length),
						bookingType = bookingFlag[parseInt(this.randomizeNumber(bookingFlag.length))-1],
						bookingId = this.createId(bookingDates.checkin, roomId);
						bookingBegining = bookingDates.checkout;
						


				let booking = {
					...bookingDates,
					roomId: rooms[roomId-1],
					bookingId,
					bookingType,
				};
				bookings = bookings.concat(booking);
			}
		}
		let bookingCollections = {
			confirmed: [],
			unconfirmed: [],
			canceled: []
		}
		for(let i = bookings.length-1; i >= 0; i--)
		{
			bookingCollections[bookings[i].bookingType][bookingCollections[bookings[i].bookingType].length] = bookings[i];
		}
		for (let [key, value] of Object.entries(bookingCollections)) {
			bookingCollections[key] = value.reverse()
		}
		this.setState(
		{
			rooms,
			bookingCollections
		})
	}
	componentDidMount(){
		this.generateDemoBookings();
	}		
	render() {
		return (
			<BookingsCalendar changeBookingCollection={this.changeBookingCollection} bookingCollections={this.state.bookingCollections} rooms={this.state.rooms} addToUnconfirmedCollection={this.addToUnconfirmedCollection} />
		);
	}
}

export default DemoBookingsCalendarContainer;