import React, { Component } from "react";

import ad_1 from './ads/ad1_sd.mp4';
import ad_2 from './ads/lc_ad2_sd.mp4';
import VideoControls from "./VideoControls";


class ArticleVideo extends Component {
  constructor () {
    super();
	  this.state = {
      sceneplay: 'hello',
      scene: -1,
      text: [],
      done: false,
      mute: true,
     }

  }

  
  muteToggle = ()=>{
    let mute = this.state.mute;
    mute = !mute;
    this.setState({
      mute
    })
  }
  


  render (){

    const {videofile} = this.props;
    const videosrc = videofile === 'ad1' ? ad_1 : videofile === 'ad2' ? ad_2 : false;
    return (
        <>
            <video id='article-video' muted src={videosrc} type="video/mp4" controls controlslist="nodownload noplaybackrate"/>
        </>
        
    )
  }
}

export default ArticleVideo;
