import React, { Component } from 'react';
import boring from './boring_square.mp4';

class ElsaPopup extends Component {

	render() {
		const elsaBoringClass = 'show';  
        const elsa = this.props.elsa;
        const openElsa = this.props.openElsa;
		return (
		<div id='elsa-popup' style={{display: elsa ? 'none' : 'initial'}} onClick={openElsa}>
			<div id='elsa-miniature'>
				{<video className={elsaBoringClass} autostart='true' muted='true' autoPlay='true' loop='true' src={boring} type="video/mp4" />}
			</div>
		</div>
		) 
	}
}

export default ElsaPopup;