import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import Calendar from './calendar-component/Calendar';
import {generateCalendar} from './calendar-component/CalendarUtility';
import BookingsList from './booking-component/BookingsList';
import BookingRoomSelect from './booking-calendar-utility/BookingRoomSelect';
import BookingTypeSelect from './booking-calendar-utility/BookingTypeSelect';
import BookingCalendarLanguageSettings from './booking-calendar-utility/BookingCalendarLanguageSettings';
import {chooseLanguage} from './booking-calendar-utility/langSettings';
import {defaultValues} from './booking-calendar-utility/defaultValues';
import {bookingTypeColors} from './booking-calendar-utility/colorsSettings';
import './calendar.css'


class BookingsCalendar extends Component {
	constructor (props) {
		super(props);
		const today = new Date ();
		this.state = {
			bookingTypeColors,
			headers: chooseLanguage('gb'),
			today: {
				day: today.getDate(), 
				month: today.getMonth(), 
				year: today.getFullYear()
			},
			currentCalendar: {
				activeCollections: {...defaultValues.calendarCollections.calendarMode},
				calendar: {}, //this object contain 3 arrays daysCalendar, idsCalendar, typesCalendar all used in calendar
				currentMonth: today.getMonth(), //current viewed month, at begging for this month
				currentYear: today.getFullYear(), //current viewed year, at begging for this month
				selectableYears: [today.getFullYear(), today.getFullYear()+1], //which years are possible to select
			},
			roomId: false, //selected room
			bookingList: [],
			newBooking: {
				array : [{
					bookingId: 'new',
					roomId: this.roomId ? this.roomId : 1,
					bookingType: 'unconfirmed',
					checkin: false,
					checkout: false,
				}],
				object: defaultValues.newBookingObject,	
				newBookingIsCorrect: false,
				newBookingMode: false,
				selectedDayCount: 0,	//coreleted to newBooking, 1st and 2nd click on calendar day while new booking is added
			},
			langSettings: {select: "no", available: ['de', 'gb', 'no', 'pl']},	
			showBookingDetails: false,
		}
		this.addBookingToUnconfirmedCollection = this.addBookingToUnconfirmedCollection.bind(this);
		this.calendarSkipTo = this.calendarSkipTo.bind(this);
		this.changeShowBookingDetailsValue = this.changeShowBookingDetailsValue.bind(this);
		this.changeCalendarCollection = this.changeCalendarCollection.bind(this);
		this.changeCalendarRoom = this.changeCalendarRoom.bind(this);
		this.createCollectionScope = this.createCollectionScope.bind(this);
		this.createBookingList = this.createBookingList.bind(this);
		this.createCalendarView = this.createCalendarView.bind(this);
		this.getSpecificBooking = this.getSpecificBooking.bind(this);
		this.returnSelectedDay = this.returnSelectedDay.bind(this);
		this.setLanguage = this.setLanguage.bind(this);
		this.toggleNewBookingMode = this.toggleNewBookingMode.bind(this);
	}	

	addBookingToUnconfirmedCollection() {		
		const newBooking = {...this.state.newBooking.object, roomId: this.state.roomId};
		let bookingId = this.props.addToUnconfirmedCollection(newBooking);
		if(bookingId)
		{
			let object = defaultValues.newBookingObject;
			let array = defaultValues.newBookingArray;
			array[0].roomId = this.roomId ? this.roomId : 1;
			this.setState({
				currentCalendar:				
				{
					...this.state.currentCalendar,
					activeCollections: {...defaultValues.calendarCollections.calendarMode}
				},
				newBooking: {
					array,
					newBookingIsCorrect: false,
					newBookingMode: false,
					object, 
				},
				showBookingDetails: bookingId
			}, () => this.createCalendarView())
		}
	}
	calendarSkipTo(month, year) {		
		this.setState({
			currentCalendar: {
				...this.state.currentCalendar,
				currentYear : year,
	    	currentMonth : month
			},
	    showBookingDetails : false
	  }, () => this.createCalendarView() );
	}
	changeCalendarCollection(inputObject){//{nameOfCollection: Boolean}
		this.setState({
			currentCalendar: {
				...this.state.currentCalendar,
				activeCollections: {
				...this.state.currentCalendar.activeCollections,
				...inputObject
				} 
			}, 
			showBookingDetails: false,
		}, () => this.createCalendarView() );
	}
	changeCalendarRoom(roomId){
		let array = defaultValues.newBookingArray;
		array[0].roomId = roomId;
		this.setState({
			roomId, 
			showBookingDetails: false, 
			newBooking : {...this.state.newBooking,	
				array,
				newBookingIsCorrect: false,
				object: {...defaultValues.newBookingObject},
				selectedDayCount: 0
			}
		}, () => this.createCalendarView());
	}
	changeShowBookingDetailsValue(showBookingDetails){
		this.setState({showBookingDetails}, () => this.createCalendarView());
	}
	createCollectionScope(activeCollections)
	{
		if (activeCollections && this.props.bookingCollections)
		{
			let collectionScope = [];
			for (let [key, value] of Object.entries(activeCollections)) {
			  if (value)
			  	collectionScope = collectionScope.concat(this.props.bookingCollections[key]);
			}
			return collectionScope;
		}
	}
	getSpecificBooking(bookingId, activeCollections)
	{
		let collectionScope = this.createCollectionScope(activeCollections);
		let booking = collectionScope.filter((booking)=>{return booking.bookingId === bookingId})
		return booking;
	}
	createBookingList(roomId, month, year, activeCollections){
		console.log(roomId);
		console.log(month);
		console.log(year);
		console.log(activeCollections);
		let collectionScope = this.createCollectionScope(activeCollections);
		let bookings;
		if (collectionScope && roomId)
		{
			bookings = collectionScope.filter((booking)=>{
				return booking.roomId === roomId 
				&& ((booking.checkin.getMonth() <= month && booking.checkout.getMonth() >= month && booking.checkin.getFullYear() === year && booking.checkout.getFullYear() === year) 
				|| ((booking.checkin.getMonth() === month && booking.checkin.getFullYear() === year) || (booking.checkout.getMonth() === month && booking.checkout.getFullYear() === year) )
				|| (booking.checkin.getFullYear() < year && booking.checkout.getMonth() >= month && booking.checkout.getFullYear() === year)
				|| (booking.checkin.getMonth() < month && booking.checkin.getFullYear() === year && booking.checkout.getFullYear() > year))

				//&& (booking.checkin.getFullYear() === year 
				//	|| booking.checkout.getFullYear() === year
				//	)
			}); 	
		}
		else
		{
			bookings = [];
		}
		return bookings;
	}
	createCalendarView(){
		let newBooking = this.state.newBooking.object.checkin.day !== false ? this.state.newBooking.object : false ;
		let bookingList = !this.state.showBookingDetails 
			?	this.createBookingList(this.state.roomId, this.state.currentCalendar.currentMonth, this.state.currentCalendar.currentYear, this.state.currentCalendar.activeCollections)
			: this.getSpecificBooking(this.state.showBookingDetails, this.state.currentCalendar.activeCollections);
		let calendar = generateCalendar(this.state.currentCalendar.currentMonth, this.state.currentCalendar.currentYear, bookingList, newBooking);
		this.setState({
			bookingList,
			currentCalendar: {
				...this.state.currentCalendar,
				calendar
			}
		});
	}

	returnSelectedDay(newBooking){
		this.setState({newBooking}, () => this.createCalendarView());
	}
	setLanguage(select) {
		const headers = chooseLanguage(select);
		this.setState({
			langSettings: {...this.state.langSettings, select},
			headers
	  });
	}	
	toggleNewBookingMode(){
		let newBookingMode = this.state.newBooking.newBookingMode;
		newBookingMode = !newBookingMode;
		let roomId = this.state.roomId ? this.state.roomId : 1;
		let array = defaultValues.newBookingArray;
		array[0].roomId = roomId;
		const newBookingModeObject = {
			currentCalendar: {
				...this.state.currentCalendar,
				activeCollections: this.state.newBooking.newBookingMode === false
				? {...defaultValues.calendarCollections.newBookingMode}
				: {...defaultValues.calendarCollections.calendarMode}
			},
			newBooking: {
				...this.state.newBooking,
				array,
				object: defaultValues.newBookingObject,
				newBookingIsCorrect: false,
				newBookingMode,
				selectedDayCount: 0
			},
			roomId,
			showBookingDetails: false,
			bookingList: [],
		}
		this.setState({...newBookingModeObject}, () => this.createCalendarView());
	}	
	componentDidMount(){
		this.setLanguage('no');
		this.createCalendarView();
  }
	
	render() {
		return (
			<div className="container-fluid">
				<div className="row justify-content-center">
			    <div className="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-1 order-md-1 order-sm-1 order-1">
					  <div style={{position: 'sticky', top: 0}}>
					    <BookingCalendarLanguageSettings 
					    	header={this.state.headers.languageHeader} //header
						    langSettings={this.state.langSettings} //object content current set and available languages
						    setLanguage={this.setLanguage} //function changing interface language
						  />
					    <BookingRoomSelect 
						    changeCalendarRoom={this.changeCalendarRoom} //function changing room on user select
						    header={this.state.headers.roomHeader}  //header
						    rooms={this.props.rooms} //[] of available rooms
						    roomId={this.state.roomId} //info about room selected by user
						  />
							{!this.state.newBooking.newBookingMode 
								&& <BookingTypeSelect 
									bookingTypeColors={this.state.bookingTypeColors} 
									calendarCollections={this.state.currentCalendar.activeCollections} //info about user select collections
									changeCalendarCollection={this.changeCalendarCollection} //function toggle active collection
									header={this.state.headers.collectionHeader} //header
									confirmed={this.state.headers.confirmed}  //header
									unconfirmed={this.state.headers.unconfirmed}  //header
									canceled={this.state.headers.canceled}  //header
								/>}
				  	</div>
				  </div>
					<div className="col-lg-5 col-md-12 col-sm-12 col-12 order-lg-2 order-md-2 order-sm-2 order-2">
					  <Calendar 
							bookingTypeColors={this.state.bookingTypeColors} //mark colors of different collections
							calendarSkipTo={this.calendarSkipTo} //function for navigation in calendar
							changeShowBookingDetailsValue={this.changeShowBookingDetailsValue} //function to toggle info about specific booking
							currentCalendar={this.state.currentCalendar} //object with all data about calendar
							createBookingList={this.createBookingList}
							headers={this.state.headers}  //header
							newBookingMode={this.state.newBooking.newBookingMode} //state of new booking mode
							newBooking={this.state.newBooking}
							roomId={this.state.roomId}
							returnSelectedDay={this.returnSelectedDay} //fuction add info about new booking
			      />
			    </div>
					<div className="col-lg-4 col-md-12 col-sm-12 col-12 order-lg-3 order-md-3 order-sm-3 order-3" style={{backgroudColor:'#FF0000'}}>
						<BookingsList
						toggleNewBookingMode={this.toggleNewBookingMode} 
						addBookingToUnconfirmedCollection={this.addBookingToUnconfirmedCollection} //function save new booking
						bookingList={ //when newBookingMode is on, in list show info only about new Booking
							this.state.newBooking.newBookingMode 
							? this.state.newBooking.array
							: this.state.bookingList
						} 
						bookingTypeColors={this.state.bookingTypeColors}
						changeBookingCollection={this.props.changeBookingCollection}
						createCalendarView={this.createCalendarView}
						header={this.state.headers.collectionHeader} //header
						confirmBookingButtonText={this.state.headers.confirmBookingButton} //header
						cancelBookingButtonText={this.state.headers.cancelBookingButton} //header
						monthHeaders={this.state.headers.monthHeaders} 
						newBookingHeader={//header on button, switch between commands during new Booking
							!this.state.newBooking.object.checkin.day && !this.state.newBooking.object.checkout.day && !this.state.newBooking.newBookingMode
							? this.state.headers.newBookingHeader //output: new booking
							: !this.state.newBooking.object.checkin.day && !this.state.newBooking.object.checkout.day && this.state.newBooking.newBookingMode
								? this.state.headers.selectCheckInHeader //output: select checkin
								: this.state.newBooking.object.checkin.day && !this.state.newBooking.object.checkout.day && this.state.newBooking.newBookingMode
									? this.state.headers.selectCheckOutHeader //output: select checkout
									: this.state.headers.addBookingHeader //output: add booking
							}
						newBookingIsCorrect={this.state.newBooking.newBookingIsCorrect}
						newBookingMode={this.state.newBooking.newBookingMode}
						showBookingDetails={this.state.showBookingDetails}/>
					</div>
				</div>
			</div>
		);
	}
}

export default BookingsCalendar;