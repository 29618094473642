import React, { Component } from "react";
import HeadCarousel from './HeadCarousel';
import NavigationBar from './NavigationBar';
import MainContent from './MainContent';
import Footer from './Footer';
import FullPage from "./FullPage";
import AboutPage from "./AboutPage";
import AboutUs from "./AboutUs";
import Preface from "./Preface";
import LangBar from "./LangBar";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.js';
import $ from 'jquery';
import 'jquery-ui'; 
import ContactPanel from "./ContactPanel";
import {chooseLanguage} from './langSettings';
import CookiesPopup from "./CookiePopup";
import Cookies from 'universal-cookie';
import {ReactComponent as Shark} from './lc_final.svg';
import Elsa from "./elsa/Elsa";
import ElsaHello from './elsa/hello.mp4'
import ElsaPopup from "./elsa/ElsaPopup";
import CallPopup from "./CallPopup";
import MailPopup from "./MailPopup";
import FullArticlePage from "./FullArticlePage";
import SnowFlakes from './GodJul/SnowFlakes';
import GodJulPopup from './GodJul/GodJulPopup';
import MenuPopup from "./MenuPopup";
import LangPopup from "./LangPopup";


class App extends Component {
  constructor () {
    super();
	  this.state = {
      elsa: false,
      lang: 'no',
      view: false,
      contactForm: ['name', 'e-mail', 'phone', 'message'],
      navigationBar: ['About', 'Contact'],
      accept: 'Jeg forstår',
      contactRules: 'Jeg godtar behandlingen av mine personlige opplysninger gitt i dette forespørselsskjemaet av Laskowski Creative for å kontakte deg og svare på det stilte spørsmålet.',
      cookiesPopup: 'På Laskowski Creative nettsider bruker vi og våre partnere informasjonskapsler (cookies) for personlig tilpasning av annonser og måling. Du kan lese mer om dette i vår personvernpolicy og vår informasjonsside om informasjonskapsler.',
      thankYouHeader: ['Takk for melding!', 'Vi kontakter deg snart.'],
      logoAnimation: 0,
      backgroundPositionX: 1600,
      siteContent: [
        {
          link: 'CodePage',
					pictures: {
            mini: 'lc_ico/lc_code.svg', 
            slider: 'lc_ico/lc_code.svg',
          },
					title: 'Kode',
					description: [
            'Webdesign',
            'Appdesign',
            'Nettadministrasjon',
            'Virtuelle nettassistenter',
            'online bookingsystemer',
            'Fotogallerier',
            'Skjemaer',
            'Applikasjonsintegrasjon'
        ],
          page: [
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Design og implementering av nettsider',
              content: [],
              caption: '',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Virtuelle nettassistenter',
              content: [],
              caption: '',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Live Chat',
              content: [],
              caption: '',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Fotogallerier',
              content: [],
              caption: '',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Skjemaer',
              content: [],
              caption: '',
            },                         
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Online bookingsystemer',
              content: [
                'Vi tilbyr omfattende tjenester innen applikasjonsutvikling og andre moderne e-business-løsninger.',
            ],
              caption: '',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Applikasjonsintegrasjon med eksisterende systemer',
              content:  [
                'Applikasjonsintegrasjon sikrer utveksling av informasjon mellom ulike applikasjoner.',
                "Målet er å lage et enhetlig system for å støtte organisasjonen som skal ta seg av alt fra kundeservice til regnskap, økonomi og lagertjenester for selskapet."
            ],
              caption: '',
            },
          ],
          caption: '',
				},
        {
          link: 'MarketingPage',
          pictures: {
            mini: 'lc_ico/lc_marketing.svg', 
            slider: 'lc_ico/lc_marketing.svg',
          },
          title: 'Online Marketing',
          description: [
            'SEO',
            'google Ads',
            'facebook Ads',
        ],
          page: [
             {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'SEO',
              content: [],
              caption: '',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'google Ads',
              content: [],
              caption: '',
            }, 
          ],
          caption: '',
        },
        {
          link: 'GraphicDesign',
          pictures: {
            mini: 'lc_ico/lc_graphic.svg',
            slider: 'lc_ico/lc_graphic.svg',
          },
          title: 'Grafisk Design',
          description:  [
            'Logo',
            'Visittkort',
            'Merkevareidentitet og markedsføring på Internett',
            ],
            page: [
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'Logo',
                content: ['Logoer omgir oss og er en integrert del av livene våre. Det kundene ser er en viktig del av selskapets image. '],
                caption: '',
              },
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'Visittkort',
                content: ['Det er viktig at visittkort er riktig utformet og trykt på papir av høy kvalitet.'],
                caption: '',
              },
            ],
          caption: '',
        },
        {
          link: 'FotoPage',
          pictures: {
            mini: 'lc_ico/lc_dron.svg', 
            slider: 'lc_ico/lc_dron.svg',  
          },
          title: 'Foto & Video',
          description: [
            'Fotografia i rejestracja wideo',
            'Ujęcia dronem',
            'Spoty reklamowe'       
          ],
          page: [
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Fotografi og videoopptak',
              content: [
                'Vi spesialiserer oss på:',
                '- bilder av arkitektur',
                '- interiør', '- reportasje',
                '- og for e-handel.'
            ],
              caption: '',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Dronefotografering',
              content:  [
                'Kampanjebilder fra en drone for selskaper, eiendomsmeglere og utviklere er vår spesialitet.',
                "Å inspisere taket med en drone og andre vanskelig tilgjengelige steder er et flott verktøy som hjelper med eiendomsgodkjenning.",

            ],
              caption: '',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Reklamefilmer',
              content:  [
                'Videomarkedsføring gir anerkjennelse og et konkurransefortrinn.',
                'En godt utført bildefilm vil gjøre det lettere for kundene å forstå merkevaren bedre, og selskapet vil øke salgsnivået. ',
                "Filmen lar deg fremheve de viktigste fordelene.",

            ],
              caption: '',
            },
          ],
          caption: '',
        },
      ],
    }
    this.showFullPage = this.showFullPage.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.openElsa = this.openElsa.bind(this);
    this.closeElsa = this.closeElsa.bind(this);
  }

  handshake() {
    const inquiry = {uid: 'LC333'};
    const address = {
        local: 'http://127.0.0.1:3003/api/lc/handshake', 
        global: 'https://laskowskicreative.no/api/lc/handshake'}
    fetch(address.global, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(inquiry)
    })
    .then((res) => res.json())      
    .then((json) => this.setState(
      {
        activatePage: json.handshake
      }
      ))  
    }

 


  componentDidMount() {
    const cookies = new Cookies();
    const cookiesAccepted = cookies.get('cookies-accepted') ? cookies.get('cookies-accepted') : false;
    const lang = cookies.get('lang') ? cookies.get('lang') : 'no';
    let langPack = chooseLanguage(lang);
    let siteContent = [...this.state.siteContent];
    let categories = ['code', 'marketing', 'graphic', 'foto']
    siteContent[0]['description'] = langPack.description['code'];
    siteContent[1]['description'] = langPack.description['marketing'];
    siteContent[2]['description'] = langPack.description['graphic'];
    siteContent[3]['description'] = langPack.description['foto'];

    siteContent.map((content, index)=>
    {
      content.title = langPack.title[index];
       content.page.map((page, idx) => {
        page.content = langPack.content[categories[index]][idx]
        page.title = langPack.description[categories[index]][idx]
        page.caption = langPack.caption[categories[index]][idx]
        return page;
      })
    })

    this.setState({
      cookiesAccepted,
      siteContent,
      lang,
      about: langPack.about,
      accept: langPack.accept,
      cookiesPopup: langPack.cookies,
      navigationBar: langPack.navigation,
      contactForm: langPack.kontakt,
      contactRules: langPack.kontaktRules
    });
    let logoAnimation = this.state.logoAnimation;
    let backgroundPositionX = this.state.backgroundPositionX;
    let direction = 'forward';
    this.interval = setInterval(() => {
      if (logoAnimation <= 11 && direction==='forward')
        this.setState({ 
          logoAnimation: logoAnimation++,

         })
      else
        direction='backward';
      if (logoAnimation > 0 && direction==='backward')
        this.setState({ 
          logoAnimation: logoAnimation--, })
      else
        direction='forward';
    }, 200);

    this.interval = setInterval(() => {
      this.setState({ 
          backgroundPositionX: backgroundPositionX++,

         })
    }, 180);  
    this.handshake();

    $( function() {
      $( "#elsa-popup" ).draggable({ axis: "y" })
    } );

    $(document).scroll(function () {
      var y = $(this).scrollTop();
      y = y+450;


      $('#page-navigation').each(function () {
        var t = 100;
        if (y > t) {
          $('nav').css("visibility", "visible");
          $(this).css("display", "flex");
          $(this).css("visibility", "visible");
          $(this).fadeIn();
        } else {
            $(this).fadeOut();
        }
      });
      $('.picture').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).slideDown();
        } else {
            $(this).slideUp();
        }
      });
      
    });
  }
  showFullPage (link) {
    this.setState({
      view: link
    })
  }

  openElsa(){
    this.setState({
      elsa: true
    })
  } 
  closeElsa(){
    this.setState({
      elsa: false
    })
  } 
  setLanguage(lang) {
    let langPack = chooseLanguage(lang);
      const cookies = new Cookies();
      let expires = new Date();
      expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
      cookies.set('lang', lang, expires.toUTCString(), {path: '/'});
    let siteContent = [...this.state.siteContent];
    let categories = ['code', 'marketing', 'graphic', 'foto'];
    siteContent[0]['description'] = langPack.description['code'];
    siteContent[1]['description'] = langPack.description['marketing'];
    siteContent[2]['description'] = langPack.description['graphic'];
    siteContent[3]['description'] = langPack.description['foto'];
    
    siteContent.map((content, index)=>
    {
      content.title = langPack.title[index];
       content.page.map((page, idx) => {
        page.content = langPack.content[categories[index]][idx]
        page.title = langPack.description[categories[index]][idx]
        page.caption = langPack.caption[categories[index]][idx]
        return page;
      })
    })

    this.setState({
      siteContent,
      lang,
      about: langPack.about,
      accept: langPack.accept,
      cookiesPopup: langPack.cookies,
      navigationBar: langPack.navigation,
      contactForm: langPack.kontakt,
      contactRules: langPack.kontaktRules
    });
  }
  

  render (){

    let headerColor = [
      'hsla(0, 0%, 100%, .6)',
      'hsla(0, 0%, 100%, .7)',
      'hsla(166, 25%, 85%, 1)',
      'radial-gradient(circle, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
      'radial-gradient(circle, rgba(141,159,171,1) 0%, rgba(9,87,121,1) 35%, rgba(3,25,39,1) 100%)',
      'linear-gradient(180deg, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
      'linear-gradient(360deg, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
      'linear-gradient(60deg,  rgba(141,159,171,1) 0%, rgba(9,87,121,1) 35%, rgba(3,25,39,1) 100%)',
      'linear-gradient(60deg, rgba(3,25,39,1) 0%,rgba(9,87,121,1) 35%, rgba(141,159,171,1) 100%)',
      'url(/lc_background_09.png)'];
    let logoAnimation = [
      'perspective( 800px ) rotateY( -17deg )', 
      'perspective( 800px ) rotateY( -15deg )',
      'perspective( 800px ) rotateY( -12deg )', 
      'perspective( 800px ) rotateY( -9deg )',
      'perspective( 800px ) rotateY( -6deg )',
      'perspective( 800px ) rotateY( -3deg )', 
      'perspective( 800px ) rotateY( 0deg )',  
      'perspective( 800px ) rotateY( 3deg )',
      'perspective( 800px ) rotateY( 6deg )', 
      'perspective( 800px ) rotateY( 9deg )',
      'perspective( 800px ) rotateY( 12deg )', 
      'perspective( 800px ) rotateY( 15deg )',
      'perspective( 800px ) rotateY( 17deg )', 
    ]
    const elsa = this.state.elsa;
    const lang = this.state.lang;
    const view = this.state.view;
    return (
      <div className="App" style={{display: this.state.activatePage === false ? 'none' : 'inherit'}}>


              <MenuPopup siteContent={this.state.siteContent} showFullPage={this.showFullPage}/>
              <LangPopup setLanguage={this.setLanguage} lang={this.state.lang} chooseLanguage={chooseLanguage}/>
              <Elsa lang={lang} elsa={elsa} openElsa={this.openElsa} closeElsa={this.closeElsa}/>
              <CallPopup/>
              <MailPopup />
              

          {
            //God Jul
            <>
            {//!view && <GodJulPopup/>
            }
            </>
          }
        
        { <header >


        {/*<img style={{transform: logoAnimation[this.state.logoAnimation]}} className='logo-full' src='./lc_logo/lc_final.svg' alt="Laskowski Creative"/>*/}
          <img className='logotype' src='./lc_logo/lc_type.svg' alt="Laskowski Creative"/>
        </header>}
            {
              <Preface showFullPage={this.showFullPage} siteContent={this.state.siteContent}/>
            }
          {view === 'CodePage' && <div id='service'>
              {
                //view === 'CodePage' && <FullPage siteContent={this.state.siteContent[0]}/>
              }
              <FullArticlePage siteContent={this.state.siteContent[0]}/>              
          </div>}
          {view === 'MarketingPage' && <div id='service'>
              {
                //view === 'CodePage' && <FullPage siteContent={this.state.siteContent[0]}/>
              }
              <FullArticlePage siteContent={this.state.siteContent[1]}/>             
          </div>}
          {view === 'GraphicDesign' && <div id='service'>
              {
                //view === 'CodePage' && <FullPage siteContent={this.state.siteContent[0]}/>
              }
              <FullArticlePage siteContent={this.state.siteContent[2]}/>             
          </div>}
          {view === 'FotoPage' && <div id='service'>
              {
                //view === 'CodePage' && <FullPage siteContent={this.state.siteContent[0]}/>
              }
              <FullArticlePage siteContent={this.state.siteContent[3]}/>              
          </div>}
      { !view && <div id='finish' > 
       
       <AboutPage about={this.state.about ? this.state.about : []}/>
       
        <ContactPanel thankYouHeader={this.state.thankYouHeader} contactHeader={this.state.navigationBar[1]} contactRules={this.state.contactRules} contactForm={this.state.contactForm}/>
       </div>}
       {
        !view && <AboutUs />
       }
        <footer>
          <Footer/>
          
        </footer>
        {/*<CookiesPopup cookiesAccepted={this.state.cookiesAccepted} accept={this.state.accept} setLanguage={this.setLanguage} lang={this.state.lang} cookiesPopup={this.state.cookiesPopup}/>*/}
      </div>
    );
  }
}

export default App;
