const products = [
    {
        name: 'Bona craft oil 2k',
        minifile: 'lc_project_01_logo.svg',
        filename: 'lc_project_01_logo.svg',
        x: 50,
        y: 50,
        scale: 100,
        description: ['Bona Craft Oil 2K er en to-komponent olje med naturlig olje med kort tørketid og god slitestyrke.',' Den kan brukes alene som en hurtigtørkende, høyytelses olje, eller kombinert med en lakk fra Traffic-familien, for å gi glans og minimere fremtidig vedlikehold. 2K-oljen fungerer perfekt med et bredt utvalg av tresorter.'],
        tags: ['','','',''],
        variations: {
            foldername: 'bona_craft_2k',
            colors: [
                {
                    description: 'Driftwood',
                    filename: 'bona_craft_oil_2k_driftwood.jpg'
                },
                {
                    description: 'Misty',
                    filename: 'bona_craft_oil_2k_misty.jpg'
                },
                {
                    description: 'Neutral',
                    filename: 'colour_block_neutral.jpg'
                },
                {
                    description: 'Clay',
                    filename: 'craft_oil_clay.jpg'
                },
                {
                    description: 'Ash',
                    filename: 'craftoil2k_ash.jpg'
                },
                {
                    description: 'Frost',
                    filename: 'craftoil2k_frost.jpg'
                },
                {
                    description: 'Graphite',
                    filename: 'craftoil2k_graphite.jpg'
                },
                {
                    description: 'Invisible',
                    filename: 'craftoil2k_invisible.jpg'
                },
                {
                    description: 'Light Grey',
                    filename: 'craftoil2k_lightgrey.jpg'
                },
                {
                    description: 'Provincial',
                    filename: 'craftoil2k_provincial.jpg'
                },
                {
                    description: 'Sand',
                    filename: 'craftoil2k_sand.jpg'
                },
                {
                    description: 'Umbra',
                    filename: 'craftoil2k_umbra.jpg'
                },
            ],
        }
    },
    {
        name: 'bona traffic hd',
        minifile: 'lc_project_02_logo.svg',
        filename: 'lc_project_02_logo.svg',
        x: 50,
        y: 50,
        scale: 100,
        description: ['Bona Traffic HD er verdens tøffeste og raskest tørkende lakk. Den gjør det mulig å gjenoppta full trafikk bare 12 timer etter påføring, og ble utviklet for påføring på store overflater.','Brukt på hoteller, restauranter og flyplasser over hele verden, hvor kort nedetid er avgjørende, er det en tregulvlakk perfeksjonert.'],
        tags: ['','','',''],
        variations: false,
    },
    {
        name: 'bona white',
        minifile: 'lc_project_03_logo.svg',
        filename: 'lc_project_03_logo.svg',
        x: 50,
        y: 50,
        scale: 70,
        description: ['Bona White er en farget vannbasert grunning for ubehandlet tregulv designet for å gi et hvitkalket utseende på lettere treslag.','Denne lakken bruker vi to ganger til hver type gulv. Etter grunnlakkering med Bona White påfører vi Bona Mega One 1k eller Bona Traffic HD 2k toppstrøk.'],
        tags: ['','','',''],
        variations: false
    },
    {
        name: 'Osmo Hadvoks-Olje',
        minifile: 'lc_project_04_logo.svg',
        filename: 'lc_project_04_logo.svg',
        x: 50,
        y: 50,
        scale: 100,
        description: ['Osmo Hardvoks-Olje er spesielt designet for bruk på gulv og andre treoverflater. Takket være sammensetningen av planteoljer og voks fremhever treet sitt naturlige utseende og er best beskyttet.','Produktet trekker inn i treet, beskytter fra innsiden, og gir en beskyttende overflate mot utvendige påvirkninger som enkelt kan punktvis vedlikeholdes og renoveres.'],
        tags: ['','','',''],
        variations: {
            foldername: 'osmo_original',
            colors: [
                {
                    description: '3011 Glanset',
                    filename: '3011_glanset.jpeg',
                },
                {
                    description: '3032 Silkematt',
                    filename: '3032_silkematt.jpeg',
                },
                {
                    description: '3062 Matt',
                    filename: '3062_matt.jpeg',
                },
                {
                    description: '3065 Semi-matt',
                    filename: '3065_semimatt.jpeg',
                },
            ]
        }
    },
    {
        name: 'bona hard wax oil',
        minifile: 'lc_project_05_logo.svg',
        filename: 'lc_project_05_logo.svg',
        x: 50,
        y: 50,
        scale: 100,
        description: ['Bona Hard Wax Oil gir det varme utseendet til et oljet gulv, og etablerer en slitesterk overflate som er punktreparerbar og lett å vedlikeholde.', 'Den er svært godt egnet for gulv med gulvvarme, og reduserer pålitelig risikoen for sidebinding.'],
        tags: ['','','',''],
        variations: false
    },
    {
        name: 'Osmo Hadvoks-Olje pigment',
        minifile: 'lc_project_06_logo.svg',
        filename: 'lc_project_06_logo.svg',
        x: 50,
        y: 50,
        scale: 70,
        description: ['Gir en svakt pigmentert farge, som er lett å jobbe med.','Hardvoksolje er perfekt for tregulv. Basert på naturlige oljer og voks, gir en slitesterk og vakker overflate som bevarer treets naturlige karakter.',  ],
        tags: ['','','',''],
        variations: {
            foldername: 'osmo_pigment',
            colors: [
                {
                    description: '3040 Hvit',
                    filename: '3040_hvit.jpeg',
                },
                {
                    description: '3041 Naturell matt',
                    filename: '3041_naturellmatt.jpeg',
                },
                {
                    description: '3067 Lys grå',
                    filename: '3067_lysgra.jpeg',
                },
                {
                    description: '3071 Honning',
                    filename: '3071_honning.jpeg',
                },
                {
                    description: '3072 Rav',
                    filename: '3072_rav.jpeg',
                },
                {
                    description: '3073 Terra',
                    filename: '3073_terra.jpeg',
                },
                {
                    description: '3074 Grafitt',
                    filename: '3074_grafitt.jpeg',
                },
                {
                    description: '3075 Svart',
                    filename: '3075_svart.jpeg',
                },
            ]
        }
    },
    {
        name: 'Bona Mega Natural',
        minifile: 'lc_project_07_logo.svg',
        filename: 'lc_project_07_logo.svg',
        x: 50,
        y: 50,
        scale: 100,
        description: ['Den perfekte lakken å velge når du ønsker å beholde det naturlige utseendet og følelsen til ubehandlet tre.','Designet for å tilby middels slitasjebeskyttelse, så ideell for spesifikasjoner i hjem eller lett trafikkerte kommersielle områder.'],
        tags: ['','','',''],
        variations: false
    },
    {
        name: 'Osmo Dekorvoks',
        minifile: 'lc_project_08_logo.svg',
        filename: 'lc_project_08_logo.svg',
        x: 50,
        y: 50,
        scale: 100,
        description: ['Osmo Dekorvoks er preget av et bredt spekter av farger.','Den påføres med en pute som påføres Osmo Hardvoks Olje eller Bona Hardwax Oil. Det brukes ikke lakk til denne typen olje.'],
        tags: ['','','',''],
        variations: {
            foldername: 'osmo_dekorvoks',
            colors: [
                {
                    description: '3102 Dampet b.',
                    filename: '3102_dampetb.jpeg',
                },
                {
                    description: '3103 Lys eik',
                    filename: '3103_lyseik.jpeg',
                },
                {
                    description: '3104 Rød',
                    filename: '3104_rod.jpeg',
                },
                {
                    description: '3105 Gul',
                    filename: '3105_gul.jpeg',
                },
                {
                    description: '3111 Hvit',
                    filename: '3111_hvit.jpeg',
                },
                {
                    description: '3115 Lys grå',
                    filename: '3115_lysgra.jpeg',
                },
                {
                    description: '3116 Leire',
                    filename: '3116_leire.jpeg',
                },
                {
                    description: '3118 Hellegrå',
                    filename: '3118_hellegra.jpeg',
                },
                {
                    description: '3119 Hav',
                    filename: '3119_hav.jpeg',
                },
                {
                    description: '3123 Gyllen brun',
                    filename: '3123_gyllenbrun.jpeg',
                },
                {
                    description: '3125 Blå',
                    filename: '3125_bla.jpeg',
                },
                {
                    description: '3129 Kongle',
                    filename: '3129_kongle.jpeg',
                },
                {
                    description: '3130 Skifer',
                    filename: '3130_skifer.jpeg',
                },
                {
                    description: '3131 Grønn',
                    filename: '3131_gronn.jpeg',
                },
                {
                    description: '3132 Grå beige',
                    filename: '3132_grabeige.jpeg',
                },
                {
                    description: '3136 Bjørk',
                    filename: '3136_bjork.jpeg',
                },
                {
                    description: '3137 Kirsebær',
                    filename: '3137_kirsebaer.jpeg',
                },
                {
                    description: '3138 Mahogny',
                    filename: '3138_mahogny.jpeg',
                },
                {
                    description: '3142 Fjell',
                    filename: '3142_fjell.jpeg',
                },
                {
                    description: '3143 Cognac',
                    filename: '3143_cognac.jpeg',
                },
                {
                    description: '3161 Ibenholt',
                    filename: '3161_ibenholt.jpeg',
                },
                {
                    description: '3164 Eik',
                    filename: '3164_eik.jpeg',
                },
                {
                    description: '3166 Valnøtt',
                    filename: '3166_valnott.jpeg',
                },
                {
                    description: '3168 Eik antikk',
                    filename: '3168_eikantikk.jpeg',
                },
                {
                    description: '3169 Svart',
                    filename: '3169_svart.jpeg',
                },
                {
                    description: '3172 Silke',
                    filename: '3172_silke.jpeg',
                },
                {
                    description: '3181 Steingrå',
                    filename: '3181_steingra.jpeg',
                },
                {
                    description: '3186 Snø matt',
                    filename: '3186_snomatt.jpeg',
                },
                {
                    description: '3188 Snø',
                    filename: '3188_sno.jpeg',
                },
                {
                    description: '3192 Sølv poppel',
                    filename: '3192_solvpop.jpeg',
                },
            ]
        }
    },
];

export {products};