import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import './popup.css'
import { getClientData } from './DataModelEngine';

class LangPopup extends Component {
    constructor () {
        super();
          this.state = {
            menuExtended: false,
          }
        }
    onClick = () => {
        let {menuExtended} = this.state;
        menuExtended = !menuExtended;
        this.setState({
            menuExtended
        })
    }
    selectMenuItem = (selected) => {
        let {showFullPage} = this.props;
        showFullPage(selected)
    }
    chooseLanguage = (event) => {
        let lang =  event.target.getAttribute('name');
        let menuExtended = this.state.menuExtended;
    this.setState({
        menuExtended: !menuExtended
    }, this.props.setLanguage(lang))
}

setCookie(lang) {
    const cookies = new Cookies();
    let expires = new Date();
  expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
    cookies.set('lang', lang, expires.toUTCString(), {path: '/'});
}

	render() {
        const {menuExtended} = this.state;
        const {setLanguage, lang} = this.props;
        const langId = lang === 'no' ? 'lang-no' : lang === 'en' ? 'lang-en' : lang === 'pl' ? 'lang-pl' : 'lang-no';
		return (
			<div id='lang-popup' onClick={this.onClick}>
                {!menuExtended && <div id={langId} className='lang-popup-hamburger' onClick={this.chooseLanguage}>
                         </div>}
                {menuExtended && <>
                    <div id='lang-popup-ico' name='no' onClick={this.chooseLanguage}>
                       <div id='lang-no' name='no' className='menu-ico'></div>
                    </div>
                    <div id='lang-popup-ico' name='en' onClick={this.chooseLanguage}>
                        <div id='lang-en'name='en' className='menu-ico'></div>
                    </div>
                    <div id='lang-popup-ico' name='pl' onClick={this.chooseLanguage}>
                        <div id='lang-pl' name='pl' className='menu-ico'></div>
                    </div> 
                    <div className='popup-backgroud'></div>                   
                </>}

			</div>
		) 
	}
}

export default LangPopup;