import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './../calendar.css';

class BookingTypeSelect extends Component {
	constructor(props){
		super(props);
		this.changeCollection = this.changeCollection.bind(this);
	}
	changeCollection(event){
		const name = event.target.name;
		let checked = this.props.calendarCollections[name];
		checked = !checked;
		const outputObject = {[name]: checked};
		this.props.changeCalendarCollection(outputObject);
	}
	render() {
		//const collections = Object.keys(this.props.calendarCollections);
		const createCollectionButtons = (calendarCollections, bookingTypeColors, index) => {
			let collectionNames = [];
			let collectionButtons = [];
			for (let [collectionName, collectionState] of Object.entries(calendarCollections)) {
				const color = bookingTypeColors[collectionName];
				collectionNames = collectionNames.concat(collectionName);
				collectionButtons = collectionButtons.concat(
					<div key={collectionName}>
						<input type="button" 
						name={collectionName} 
						onClick={this.changeCollection} 
						style={{width: "60px", height: "60px"}}
						className={collectionState ? "btn btn-"+color : "btn btn-outline-"+color}/>
						<label htmlFor={collectionName}><h6>{this.props[collectionName]}</h6></label>
				</div>);
			}
			return {collectionNames, collectionButtons}
		};
		const collections = createCollectionButtons(this.props.calendarCollections, this.props.bookingTypeColors);
		return (
			<div id="reservation-buttons">
				<h3>{this.props.header}</h3>
				{collections.collectionNames.map((collection, index) =>
					collections.collectionButtons[index]
				)}
			</div>
		);
	}
}

BookingTypeSelect.propTypes ={
	calendarCollections: PropTypes.objectOf(PropTypes.bool).isRequired,
	bookingTypeColors: PropTypes.objectOf(PropTypes.string).isRequired,
	changeCalendarCollection: PropTypes.func.isRequired,

}

export default BookingTypeSelect;

